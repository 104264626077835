import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  apiGereja,
  apiKatPubs,
  apiNews,
  keyGereja,
  keyKatPubs,
  keyNews,
} from "../../libs/vars";
import { DataKatPublikasi, DataGereja } from "../../libs/context";
import FormFunction from "../../components/Forms.function";
import { FormAddButton } from "../../components/Forms.buttons";
import { AddInput, FormContainer } from "../../components/Forms.layout";
import Hero from "../../components/Hero";

const AddBerita = ({ pageTitle }) => {
  const home = "/berita";
  // const pageTitle = "Berita";
  const { katPublikasiState } = useContext(DataKatPublikasi);
  const { gerejaState } = useContext(DataGereja);
  const [isLoading, setIsLoading] = useState(false);
  const [idPublikasi, setIdPubs] = useState("");
  const [katPublikasi, setKategori] = useState("");
  const [idGereja, setIdGereja] = useState("");
  const [tglPublikasi, setTglPublikasi] = useState("");
  const [author, setAuthor] = useState("");
  const [judulPublikasi, setJudul] = useState("");
  const [isiPublikasi, setIsi] = useState("");
  const [coverPublikasi, setCover] = useState("");
  const [highlight, setHighlight] = useState("");
  const [dataKatPub, setDataKatPub] = useState([]);
  const [loadingKatPub, setLoadingKatPub] = useState(false);
  const [dataGereja, setDataGereja] = useState([]);
  const [loadingGereja, setLoadingGereja] = useState(false);
  const [msg, setMsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isError, setIsError] = useState(false);
  // const history = useHistory();
  const [states, setStates] = useState({
    isLoading: false,
    idPublikasi: "",
    katPublikasi: "",
    idGereja: "",
    tglPublikasi: "",
    author: "",
    judulPublikasi: "",
    isiPublikasi: "",
    coverPublikasi: "",
    highlight: "",
    dataKatPub: [],
    loadingKatPub: false,
    dataGereja: [],
    loadingGereja: false,
    msg: "",
    isSubmit: false,
    isError: false,
  });

  // useEffect(() => {
  //   getPublikasi();
  //   getGereja();
  // }, []);

  const Register = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const payload = {
      id: idPublikasi,
      kategori_berita: katPublikasi,
      id_grj: idGereja,
      tgl_berita: tglPublikasi,
      author: author,
      judul_berita: judulPublikasi,
      isi_berita: isiPublikasi,
      cover_berita: coverPublikasi,
      featured: highlight,
    };
    const options = {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyNews,
      },
    };
    await axios
      .post(apiNews + "/v1", payload, options)
      .then(() => {
        setIsLoading(false);
        setMsg("Data tersimpan!");
        setIsSubmit(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setMsg(error.message);
        setIsError(true);
      });
  };

  if (isSubmit) {
    return <FormFunction title="" text={msg} push={home} status={200} />;
  }

  if (isError) {
    return (
      <FormFunction
        title=""
        text={"Gagal menyimpan data. Coba kembali beberapa saat. (" + msg + ")"}
        push={home}
        status={500}
      />
    );
  }

  const getPublikasi = async () => {
    setLoadingKatPub(true);
    const options = {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyKatPubs,
      },
    };
    const response = await axios.get(apiKatPubs + "/v1", options);
    setDataKatPub(response.data);
    setLoadingKatPub(false);
  };

  const getGereja = async () => {
    setLoadingGereja(true);
    const options = {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyGereja,
      },
    };
    const response = await axios.get(apiGereja + "/v1", options);
    setDataGereja(response.data);
    setLoadingGereja(false);
  };

  return (
    <>
      {/* <Hero title={pageTitle} subtitle={null} /> */}
      <FormContainer>
        <form onSubmit={Register} className="box">
          <p className="has-text-centered">{msg}</p>
          <AddInput
            label={"ID Berita"}
            value={idPublikasi}
            change={(e) => setIdPubs(e.target.value)}
            type={"text"}
            isRequired={true}
          />
          <div className="field mt-5">
            <label className="label">Pilih Kategori</label>
            <div className="control">
              <div className={`select ${loadingKatPub && "is-loading"}`}>
                {katPublikasiState && katPublikasiState.length > 0 ? (
                  <select
                    value={katPublikasi}
                    onChange={(e) => setKategori(e.target.value)}
                  >
                    {katPublikasiState.map((item) => (
                      <option key={item.id} value={item.nama}>
                        {item.nama}
                      </option>
                    ))}
                  </select>
                ) : (
                  <select disabled>
                    <option>Data kosong</option>
                  </select>
                )}
              </div>
            </div>
          </div>
          <div className="field mt-5">
            <label className="label">ID Gereja</label>
            <div className="control">
              <div className={`select ${loadingGereja && "is-loading"}`}>
                {gerejaState && gerejaState.length > 0 ? (
                  <select
                    value={idGereja}
                    onChange={(e) => setIdGereja(e.target.value)}
                  >
                    {gerejaState.map((item) => (
                      <option key={item.id} value={item.nm_grj}>
                        {item.nm_grj}
                      </option>
                    ))}
                  </select>
                ) : (
                  <select disabled>
                    <option>Data kosong</option>
                  </select>
                )}
              </div>
            </div>
          </div>
          {/* <AddInput
            label={"ID Gereja"}
            value={idGereja}
            change={(e) => setIdGereja(e.target.value)}
            type={"text"}
            isRequired={false}
          /> */}
          <AddInput
            label={"Tanggal Berita"}
            value={tglPublikasi}
            change={(e) => setTglPublikasi(e.target.value)}
            type={"text"}
            isRequired={false}
          />
          <AddInput
            label={"Author"}
            value={author}
            change={(e) => setAuthor(e.target.value)}
            type={"text"}
            isRequired={true}
          />
          <AddInput
            label={"Judul Berita"}
            value={judulPublikasi}
            change={(e) => setJudul(e.target.value)}
            type={"text"}
            isRequired={true}
          />
          <div class="field mt-5">
            <label className="label">Isi Berita</label>
            <div class="control">
              <textarea
                class="textarea is-large"
                placeholder=""
                value={isiPublikasi}
                onChange={(e) => setIsi(e.target.value)}
                required="required"
              ></textarea>
            </div>
          </div>
          <div className="file has-name mt-5">
            <label className="file-label">
              <input
                className="file-input"
                type="file"
                onChange={(e) => setCover(e.target.value)}
                value={coverPublikasi}
              />
              <span className="file-cta">
                <span className="file-icon">
                  <i className="fas fa-upload"></i>
                </span>
                <span className="file-label">Unggah gambar cover…</span>
              </span>
              <span className="file-name"></span>
            </label>
          </div>
          <div className="field mt-5">
            <label className="label">Set Highlight?</label>
            <div className="control">
              <div className="select">
                <select
                  value={highlight}
                  onChange={(e) => setHighlight(e.target.value)}
                >
                  <option value="false">Tidak</option>
                  <option value="true">Ya</option>
                </select>
              </div>
            </div>
          </div>
          <div className="field mt-5">
            <FormAddButton type={"save"} loading={isLoading} goto={null} />
            <FormAddButton type={"back"} loading={null} goto={home} />
          </div>
        </form>
      </FormContainer>
    </>
  );
};

export default AddBerita;
