/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useMemo } from "react";
// import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import { Column, HeaderCell, Cell } from "rsuite-table";
import axios from "axios";
import jwt_decode from "jwt-decode";
import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import { Layout, Space, Table, Button, Typography, theme } from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { apiUrl, apiWilayah, keyWilayah } from "../libs/vars";
import { DataWilayah } from "../libs/context";
import { WilayahStore } from "../state/Wilayah";
import Topbar from "../components/Topbar";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import { Footer } from "../components/Footer";
import ViewWilayah from "./components/Wilayah.details";
import { IndexContainer } from "../components/Index.layout";
import "rsuite-table/dist/css/rsuite-table.css";

const { Content } = Layout;
const { Title } = Typography;

const WilayahStateSelector = (state) => state.dataState;

const Wilayah = ({ home }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const pageTitle = "Wilayah";
  const { path } = useRouteMatch();
  const [msg, setMessage] = useState("");
  // const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  // const [wilayah, setWilayah] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const wilayahState = WilayahStore(WilayahStateSelector);
  const { updateWilayahStore } = WilayahStore();

  useEffect(() => {
    refreshToken();
    // getWilayah();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(apiUrl + "/auth/token");
      setToken(response.data.accessToken);
      const decoded = jwt_decode(response.data.accessToken);
      // setName(decoded.name);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        history.push("/");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(apiUrl + "/auth/token");
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        // setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getWilayah = async () => {
    setIsLoading(true);
    try {
      const options = {
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "X-API-Key": keyWilayah,
        },
      };
      const response = await axiosJWT.get(apiWilayah + "/v1", options);
      // setWilayah(response.data);
      // localStorage.setItem("dataWilayah", JSON.stringify(response.data));
      updateWilayahStore(response.data);
      setIsLoading(false);
    } catch (error) {
      if (error.response) {
        setIsLoading(false);
        setMessage("Gagal memuat data!");
      }
    }
  };

  const contextValue = useMemo(
    () => ({ wilayahState, getWilayah, setIsLoading, isLoading, pageTitle }),
    [wilayahState, getWilayah]
  );

  return (
    <>
      {/* <Navbar /> */}
      <Sidebar />
      <Layout>
        <Topbar />
        <Content style={{ margin: "24px 16px 0" }}>
          <Title level={1} style={{ margin: "16px 0" }}>
            {pageTitle}
          </Title>
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
            }}
          >
            <Switch>
              <DataWilayah.Provider value={contextValue}>
                <Route exact path={`${path}`}>
                  <Home msg={msg} />
                </Route>
                {/* <Route path={`${path}/edit/:id`}>
          <EditRayon home={home} />
        </Route> */}
                <Route path={`${path}/details/:id`}>
                  <ViewWilayah title={pageTitle} home={home} />
                </Route>
              </DataWilayah.Provider>
            </Switch>
          </div>
        </Content>
        {/* <Footer style={{ textAlign: "center" }}>MYGPDI Backend ©2023</Footer> */}
        {/* <Footer /> */}
      </Layout>
    </>
  );
};

const Home = () => {
  const history = useHistory();
  const { pageTitle, isLoading, getWilayah } = useContext(DataWilayah);

  function goTo() {
    history.push("/forms/wilayah");
  }

  return (
    <>
      {/* <Hero title={pageTitle} subtitle={null} /> */}
      <IndexContainer add={goTo} loading={isLoading} refresh={getWilayah}>
        <div className="table-container">
          {/* <Tabel /> */}
          <TabelAlt />
        </div>
      </IndexContainer>
    </>
  );
};

const Tabel = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { getWilayah, wilayahState, isLoading } = useContext(DataWilayah);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  async function Delete(key) {
    setLoading(true);
    const options = {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyWilayah,
      },
    };
    const konfirmasi = window.confirm("Yakin mau menghapus ID: " + key + "?");
    if (konfirmasi) {
      await axios
        .delete(apiWilayah + "/v1/" + key, options)
        .then((response) => {
          alert("Data berhasil dihapus.");
          setLoading(false);
          getWilayah();
          setMessage(response);
        })
        .catch((error) => {
          setLoading(false);
          setMessage(error);
        });
    } else {
      setLoading(false);
    }
  }

  return (
    <Table
      data={wilayahState}
      loading={isLoading}
      autoHeight
      cellBordered
      wordWrap="break-word"
    >
      <Column width={50} fixed resizable>
        <HeaderCell>No</HeaderCell>
        <Cell>
          {(rows, index) => {
            return index + 1;
          }}
        </Cell>
      </Column>
      <Column width={100} sortable fixed resizable>
        <HeaderCell>ID</HeaderCell>
        <Cell dataKey="id" />
      </Column>
      <Column width={200} sortable fixed flexGrow={1}>
        <HeaderCell>Nama</HeaderCell>
        <Cell dataKey="nm_wil" />
      </Column>
      <Column width={200} fixed flexGrow={2}>
        <HeaderCell>No Wilayah</HeaderCell>
        <Cell dataKey="nmr_wil" />
      </Column>
      <Column width={250} fixed>
        <HeaderCell>Tindakan</HeaderCell>
        <Cell>
          {(rows) => {
            return (
              <Space wrap>
                <Button
                  type="primary"
                  size="small"
                  disabled={loading}
                  icon={<EyeOutlined />}
                  onClick={() => history.push(`${path}/details/${rows.id}`)}
                >
                  Detail
                </Button>
                <Button
                  size="small"
                  disabled={loading}
                  icon={<EditOutlined />}
                  onClick={() => history.push(`${path}/edit/${rows.id}`)}
                >
                  Edit
                </Button>
                <Button
                  type="dashed"
                  size="small"
                  loading={loading}
                  disabled={loading}
                  icon={<DeleteOutlined />}
                  danger
                  onClick={() => Delete(rows.id)}
                >
                  Hapus
                </Button>
              </Space>
            );
          }}
        </Cell>
      </Column>
    </Table>
  );
};

function TabelAlt() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { getWilayah, wilayahState, isLoading } = useContext(DataWilayah);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  async function Delete(key) {
    setLoading(true);
    const options = {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyWilayah,
      },
    };
    const konfirmasi = window.confirm("Yakin mau menghapus ID: " + key + "?");
    if (konfirmasi) {
      await axios
        .delete(apiWilayah + "/v1/" + key, options)
        .then((response) => {
          alert("Data berhasil dihapus.");
          setLoading(false);
          getWilayah();
          setMessage(response);
        })
        .catch((error) => {
          setLoading(false);
          setMessage(error);
        });
    } else {
      setLoading(false);
    }
  }

  const kolom = [
    // {
    //   title: "No",
    //   key: "no",
    //   render: (rows, index) => {
    //     return index + 1;
    //   },
    // },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (rows) => <Link to={`${path}/details/${rows}`}>{rows}</Link>,
    },
    {
      title: "Nama",
      dataIndex: "nm_wil",
      key: "nama",
      responsive: ["md"],
    },
    {
      title: "Nomor",
      dataIndex: "nmr_wil",
      key: "nomor",
      responsive: ["md"],
    },
    {
      title: "Tindakan",
      key: "action",
      render: (_, rows) => (
        <Space size="middle" wrap>
          {/* <Button
            type="primary"
            size="small"
            disabled={loading}
            icon={<EyeOutlined />}
            onClick={() => history.push(`${path}/details/${rows.id}`)}
          >
            Detail
          </Button> */}
          <Button
            size="small"
            disabled={loading}
            icon={<EditOutlined />}
            onClick={() => history.push(`${path}/edit/${rows.id}`)}
          >
            Edit
          </Button>
          <Button
            type="dashed"
            size="small"
            loading={loading}
            disabled={loading}
            icon={<DeleteOutlined />}
            danger
            onClick={() => Delete(rows.id)}
          >
            Hapus
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Table
      dataSource={wilayahState}
      loading={isLoading}
      columns={kolom}
      pagination={{ pageSize: 25 }}
    ></Table>
  );
}

export default Wilayah;
