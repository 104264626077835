/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Col,
  Row,
  Statistic,
  Layout,
  Typography,
  Button,
  theme,
} from "antd";
import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import { nanoid } from "nanoid/non-secure";

// import Hero from "../components/Hero";
import {
  apiGereja,
  apiJadwal,
  apiJemaat,
  apiNews,
  apiPendeta,
  apiRayon,
  apiSermon,
  apiUrl,
  apiWilayah,
  keyGereja,
  keyJadwal,
  keyJemaat,
  keyNews,
  keyPendeta,
  keyRayon,
  keySermon,
  keyWilayah,
} from "../libs/vars";
import Topbar from "../components/Topbar";
import Sidebar from "../components/Sidebar";
import { NumberedCards } from "./components/Cards";
import { usernameStore } from "../state/userName";
import { UserStore } from "../state/Users";
import { RayonStore } from "../state/Rayon";
import { JemaatStore } from "../state/Jemaat";
import { GerejaStore } from "../state/Gereja";
import { PendetaStore } from "../state/Pendeta";
import { WilayahStore } from "../state/Wilayah";
import { BeritaStore } from "../state/Berita";
import { JadwalStore } from "../state/Jadwal";
import { KhotbahStore } from "../state/Khotbah";
import { FooterSimple } from "../components/Footer";

const { Header, Content } = Layout;
const { Title } = Typography;

// Prevent re-render or using useCallback
const UserStateSelector = (state) => state.dataState;
const RayonStateSelector = (state) => state.dataState;
const JemaatStateSelector = (state) => state.dataState;
const GerejaStateSelector = (state) => state.dataState;
const PendetaStateSelector = (state) => state.dataState;
const WilayahStateSelector = (state) => state.dataState;
const BeritaStateSelector = (state) => state.dataState;
const JadwalStateSelector = (state) => state.dataState;
const KhotbahStateSelector = (state) => state.dataState;

const Dashboard = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const pageTitle = "Dashboard";
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const [userLoading, setUserLoading] = useState(false);
  const [jemaatLoading, setJemaatLoading] = useState(false);
  const [gerejaLoading, setGerejaLoading] = useState(false);
  const [pendetaLoading, setPendetaLoading] = useState(false);
  const [rayonLoading, setRayonLoading] = useState(false);
  const [wilayahLoading, setWilayahLoading] = useState(false);
  const [beritaLoading, setBeritaLoading] = useState(false);
  const [jadwalLoading, setJadwalLoading] = useState(false);
  const [khotbahLoading, setKhotbahLoading] = useState(false);
  const history = useHistory();

  const userState = UserStore(UserStateSelector);
  const rayonState = RayonStore(RayonStateSelector);
  const jemaatState = JemaatStore(JemaatStateSelector);
  const gerejaState = GerejaStore(GerejaStateSelector);
  const pendetaState = PendetaStore(PendetaStateSelector);
  const wilayahState = WilayahStore(WilayahStateSelector);
  const beritaState = BeritaStore(BeritaStateSelector);
  const jadwalState = JadwalStore(JadwalStateSelector);
  const khotbahState = KhotbahStore(KhotbahStateSelector);
  const { updateUsername, updateUsernameAuth } = usernameStore();
  const { updateUserStore, updateUserAuth, fetchUserStore } = UserStore();
  const { updateRayonStore, updateRayonAuth } = RayonStore();
  const { updateJemaatStore, updateJemaatAuth } = JemaatStore();
  const { updateGerejaStore, updateGerejaAuth } = GerejaStore();
  const { updatePendetaStore, updatePendetaAuth } = PendetaStore();
  const { updateWilayahStore, updateWilayahAuth } = WilayahStore();
  const { updateBeritaStore, updateBeritaAuth } = BeritaStore();
  const { updateJadwalStore, updateJadwalAuth } = JadwalStore();
  const { updateKhotbahStore, updateKhotbahAuth } = KhotbahStore();
  const countUser = userState.length;
  const countRayon = rayonState.length;
  const countJemaat = jemaatState.length;
  const countGereja = gerejaState.length;
  const countPendeta = pendetaState.length;
  const countWilayah = wilayahState.length;
  const countBerita = beritaState.length;
  const countJadwal = jadwalState.length;
  const countKhotbah = khotbahState.length;

  useEffect(() => {
    refreshToken();
    Promise.all([
      getRayonStore(),
      getJemaatStore(),
      getUserStore(),
      getGerejaStore(),
      getPendetaStore(),
      getWilayahStore(),
      getBeritaStore(),
      getJadwalStore(),
      getKhotbahStore(),
    ]);
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(apiUrl + "/auth/token");
      setToken(response.data.accessToken);
      updateUsernameAuth(response.data.accessToken);
      const decoded = jwt_decode(response.data.accessToken);
      updateUsername(decoded.name);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        history.push("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(apiUrl + "/auth/token");
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        updateUsernameAuth(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        updateUsername(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getUserStore = async () => {
    setUserLoading(true);
    const response = await axiosJWT.get(apiUrl + "/users", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    updateUserStore(response.data);
    const key = nanoid(64);
    updateUserAuth(key);
    setUserLoading(false);
  };

  const getRayonStore = async () => {
    setRayonLoading(true);
    const response = await axiosJWT.get(apiRayon + "/v1", {
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${token}`,
        "X-API-Key": keyRayon,
      },
    });
    updateRayonStore(response.data);
    const key = nanoid(64);
    updateRayonAuth(key);
    setRayonLoading(false);
  };

  const getJemaatStore = async () => {
    setJemaatLoading(true);
    const response = await axiosJWT.get(apiJemaat + "/v1", {
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${token}`,
        "X-API-Key": keyJemaat,
      },
    });
    updateJemaatStore(response.data);
    const key = nanoid(64);
    updateJemaatAuth(key);
    setJemaatLoading(false);
  };

  const getGerejaStore = async () => {
    setGerejaLoading(true);
    const response = await axiosJWT.get(apiGereja + "/v1", {
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${token}`,
        "X-API-Key": keyGereja,
      },
    });
    updateGerejaStore(response.data);
    const key = nanoid(64);
    updateGerejaAuth(key);
    setGerejaLoading(false);
  };

  const getPendetaStore = async () => {
    setPendetaLoading(true);
    const response = await axiosJWT.get(apiPendeta + "/v1", {
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${token}`,
        "X-API-Key": keyPendeta,
      },
    });
    updatePendetaStore(response.data);
    const key = nanoid(64);
    updatePendetaAuth(key);
    setPendetaLoading(false);
  };

  const getWilayahStore = async () => {
    setWilayahLoading(true);
    const response = await axiosJWT.get(apiWilayah + "/v1", {
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${token}`,
        "X-API-Key": keyWilayah,
      },
    });
    updateWilayahStore(response.data);
    const key = nanoid(64);
    updateWilayahAuth(key);
    setWilayahLoading(false);
  };

  const getBeritaStore = async () => {
    setBeritaLoading(true);
    const response = await axiosJWT.get(apiNews + "/v1", {
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${token}`,
        "X-API-Key": keyNews,
      },
    });
    updateBeritaStore(response.data);
    const key = nanoid(64);
    updateBeritaAuth(key);
    setBeritaLoading(false);
  };

  const getJadwalStore = async () => {
    setJadwalLoading(true);
    const response = await axiosJWT.get(apiJadwal + "/v1", {
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${token}`,
        "X-API-Key": keyJadwal,
      },
    });
    updateJadwalStore(response.data);
    const key = nanoid(64);
    updateJadwalAuth(key);
    setJadwalLoading(false);
  };

  const getKhotbahStore = async () => {
    setKhotbahLoading(true);
    const response = await axiosJWT.get(apiSermon + "/v1", {
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${token}`,
        "X-API-Key": keySermon,
      },
    });
    updateKhotbahStore(response.data);
    const key = nanoid(64);
    updateKhotbahAuth(key);
    setKhotbahLoading(false);
  };

  return (
    <>
      <Sidebar />
      <Layout>
        <Topbar />
        <Content style={{ margin: "24px 16px 0" }}>
          <Title level={1} style={{ margin: "16px 0" }}>
            {pageTitle}
          </Title>
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
            }}
          >
            <Row gutter={[16, 16]}>
              <NumberedCards
                title="Jumlah Jemaat"
                script={countJemaat}
                loading={jemaatLoading}
                span={6}
              />
              <NumberedCards
                title="Jumlah Gereja"
                script={countGereja}
                loading={gerejaLoading}
                span={6}
              />
              <NumberedCards
                title="Jumlah Pendeta"
                script={countPendeta}
                loading={pendetaLoading}
                span={6}
              />
              <NumberedCards
                title="Jumlah Rayon"
                script={countRayon}
                loading={rayonLoading}
                span={6}
              />
              <NumberedCards
                title="Jumlah Wilayah"
                script={countWilayah}
                loading={wilayahLoading}
                span={6}
              />
              <NumberedCards
                title="Jumlah Berita"
                script={countBerita}
                loading={beritaLoading}
                span={6}
              />
              <NumberedCards
                title="Jumlah Jadwal"
                script={countJadwal}
                loading={jadwalLoading}
                span={6}
              />
              <NumberedCards
                title="Jumlah Khotbah"
                script={countKhotbah}
                loading={khotbahLoading}
                span={6}
              />
              <NumberedCards
                title="Backend User"
                script={countUser}
                loading={userLoading}
                span={6}
              />
            </Row>
          </div>
        </Content>
        {/* <Footer style={{ textAlign: "center" }}>MYGPDI Backend ©2023</Footer> */}
        <FooterSimple />
      </Layout>
    </>
  );
};

export default Dashboard;
