import React, { useState } from "react";
import axios from "axios";
import { apiRayon, keyRayon } from "../libs/vars";
import { RayonStore } from "../state/Rayon";
import { FormContainer, AddInput } from "./Forms.layout";
import Hero from "./Hero";
import FormFunction from "./Forms.function";
import { FormAddButton } from "./Forms.buttons";

const AddRayon = ({ pageTitle }) => {
  // const pageTitle = "Rayon";
  const home = "/rayon";
  const [isLoading, setIsLoading] = useState(false);
  const [idRayon, setIdRayon] = useState("");
  const [namaRayon, setNamaRayon] = useState("");
  const [ketuaRayon, setKetuaRayon] = useState("");
  const [wilRayon, setWilRayon] = useState("");
  const [msg, setMsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isError, setIsError] = useState(false);
  const { fetchRayonStore } = RayonStore();

  const Register = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const payload = {
      id: idRayon,
      nm_rayon: namaRayon,
      ket_rayon: ketuaRayon,
      wil_rayon: wilRayon,
    };
    const options = {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyRayon,
      },
    };
    // try {
    //   const response = await axios.post(apiRayon + "/v1", payload, options);
    //   if (response) {
    //     setIsLoading(false);
    //     setMsg("Data tersimpan!");
    //     setIsSubmit(true);
    //   }
    // } catch (error) {
    //   setIsLoading(false);
    //   setMsg(error.message);
    //   setIsError(true);
    // }
    await axios
      .post(apiRayon + "/v1", payload, options)
      .then(() => {
        fetchRayonStore();
        setIsLoading(false);
        setMsg("Data tersimpan!");
        setIsSubmit(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setMsg(error.message);
        setIsError(true);
      });
  };

  if (isSubmit) {
    return <FormFunction text={msg} push={home} status={200} />;
  }

  if (isError) {
    return (
      <FormFunction
        text={"Gagal menyimpan data. Coba kembali beberapa saat. (" + msg + ")"}
        push={home}
        status={500}
      />
    );
  }

  return (
    <>
      {/* <Hero title={pageTitle} subtitle={null} /> */}
      <FormContainer>
        <form onSubmit={Register} className="box">
          <p className="has-text-centered">{msg}</p>
          <AddInput
            label={"ID Rayon"}
            value={idRayon}
            change={(e) => setIdRayon(e.target.value)}
            type={"text"}
            isRequired={true}
          />
          <AddInput
            label={"Nama Rayon"}
            value={namaRayon}
            change={(e) => setNamaRayon(e.target.value)}
            type={"text"}
            isRequired={true}
          />
          <AddInput
            label={"Ketua Rayon"}
            value={ketuaRayon}
            change={(e) => setKetuaRayon(e.target.value)}
            type={"text"}
            isRequired={true}
          />
          <AddInput
            label={"Wilayah Rayon"}
            value={wilRayon}
            change={(e) => setWilRayon(e.target.value)}
            type={"text"}
            isRequired={true}
          />
          <div className="field mt-5">
            <FormAddButton type={"save"} loading={isLoading} goto={null} />
            <FormAddButton type={"back"} loading={null} goto={home} />
          </div>
        </form>
      </FormContainer>
    </>
  );
};

export default AddRayon;
