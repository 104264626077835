import { Statistic } from "antd";
import { Card } from "antd";
import { Col } from "antd";

export function NumberedCards({ title, script, loading, span }) {
  return (
    <Col span={span}>
      <Card bordered={false}>
        <Statistic
          title={title}
          value={script}
          loading={loading}
          // valueStyle={{ color: '#cf1322' }}
          // prefix={<ArrowDownOutlined />}
          // suffix="%"
        />
      </Card>
    </Col>
  );
}
