import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";

import { apiRayon, keyRayon } from "../../libs/vars";
import { FormContainer, EditInput } from "../../components/Forms.layout";
import { FormEditButton } from "../../components/Forms.buttons";
import FormFunction from "../../components/Forms.function";
import Hero from "../../components/Hero";
import { DataRayon } from "../../libs/context";

const EditRayon = ({ home }) => {
  // const home = "/rayon";
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [idRayon, setIdRayon] = useState("");
  const [namaRayon, setNamaRayon] = useState("");
  const [ketuaRayon, setKetuaRayon] = useState("");
  const [wilRayon, setWilRayon] = useState("");
  const [msg, setMsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isError, setIsError] = useState(false);
  const history = useHistory();
  const { pageTitle, rayonState } = useContext(DataRayon);

  const [str, setStr] = useState({
    id: "",
    nama: "",
    ketua: "",
    wilayah: "",
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    // const data = JSON.parse(localStorage.getItem("dataRayon"));
    // const filterData = data.filter((item) => item.id === id);
    const filterData = rayonState.filter((item) => item.id === id);
    if (filterData) {
      setStr({
        id: filterData.map((item) => item.id),
        nama: filterData.map((item) => item.nm_rayon),
        ketua: filterData.map((item) => item.ket_rayon),
        wilayah: filterData.map((item) => item.wil_rayon),
      });
    }
  };

  const Update = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const payload = {
      id: idRayon,
      nm_rayon: namaRayon,
      ket_rayon: ketuaRayon,
      wil_rayon: wilRayon,
    };
    const options = {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyRayon,
      },
    };
    await axios
      .patch(apiRayon + "/v1/" + id, payload, options)
      .then((response) => {
        setIsLoading(false);
        setMsg(response);
        setIsSubmit(true);
      })
      .catch((error) => {
        setIsLoading(false);
        setMsg(error);
        setIsError(true);
      });
  };

  if (isSubmit) {
    return (
      <FormFunction
        title=""
        text={"Data tersimpan!"}
        push={home}
        status={200}
      />
    );
  }

  if (isError) {
    return (
      <FormFunction
        title=""
        text={"Gagal mengirim data. Coba kembali beberapa saat."}
        push={home}
        status={500}
      />
    );
  }

  return (
    <>
      {/* <Hero title={pageTitle} subtitle={null} /> */}
      <FormContainer>
        <form onSubmit={Update} className="box">
          <EditInput
            label={"ID Rayon"}
            value={str.id}
            change={(e) => setIdRayon(e.target.value)}
            type={"text"}
          />
          <EditInput
            label={"Nama Rayon"}
            value={str.nama}
            change={(e) => setNamaRayon(e.target.value)}
            type={"text"}
          />
          <EditInput
            label={"Ketua Rayon"}
            value={str.ketua}
            change={(e) => setKetuaRayon(e.target.value)}
            type={"text"}
          />
          <EditInput
            label={"Wilayah Rayon"}
            value={str.wilayah}
            change={(e) => setWilRayon(e.target.value)}
            type={"text"}
          />
          <div className="field mt-5">
            <FormEditButton type={"save"} loading={isLoading} goto={null} />
            <FormEditButton type={"back"} loading={null} goto={home} />
          </div>
        </form>
      </FormContainer>
    </>
  );
};

export default EditRayon;
