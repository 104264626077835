import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import { Button, Descriptions, Space } from "antd";
import { ArrowLeftOutlined, DeleteOutlined } from "@ant-design/icons";

import { apiSermon, keySermon } from "../../libs/vars";
import { DataKhotbah } from "../../libs/context";
import { FormContainer, ViewInput } from "../../components/Forms.layout";
import Hero from "../../components/Hero";
import FormFunction from "../../components/Forms.function";
import { FormDetailsButton } from "../../components/Forms.buttons";

const ViewKhotbah = ({ title, home }) => {
  const history = useHistory();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isError, setIsError] = useState(false);
  const { pageTitle, khotbahState } = useContext(DataKhotbah);

  const [str, setStr] = useState({
    id: "",
    kategori: "",
    gereja: "",
    pendeta: "",
    tanggal: "",
    author: "",
    judul: "",
    isi: "",
    cover: "",
    highlight: "",
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    // const data = JSON.parse(localStorage.getItem("dataKhotbah"));
    // const filterData = data.filter((item) => item.id === id);
    const filterData = khotbahState.filter((item) => item.id === id);
    if (filterData) {
      setStr({
        id: filterData.map((item) => item.id),
        kategori: filterData.map((item) => item.kategori_khotbah),
        gereja: filterData.map((item) => item.id_grj),
        pendeta: filterData.map((item) => item.id_pdt),
        tanggal: filterData.map((item) => item.tgl_khotbah),
        author: filterData.map((item) => item.author),
        judul: filterData.map((item) => item.judul_khotbah),
        isi: filterData.map((item) => item.isi_khotbah),
        cover: filterData.map((item) => item.cover_khotbah),
        highlight: filterData.map((item) => item.featured),
      });
    }
  };

  const Delete = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const options = {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keySermon,
      },
    };
    const konfirmasi = window.prompt(
      "Isi dengan " + str.id + " untuk menghapus data."
    );
    if (konfirmasi === str.id) {
      await axios
        .delete(apiSermon + "/v1/" + id, options)
        .then(() => {
          localStorage.removeItem("singleKhotbah");
          setIsLoading(false);
          setIsSubmit(true);
          setMsg("Data berhasil dihapus!");
        })
        .catch((error) => {
          setIsLoading(false);
          setMsg("Gagal menghapus data. Coba kembali beberapa saat.");
          console.log(error);
          setIsError(true);
        });
    } else {
      setIsLoading(false);
      setMsg("Isian salah! Gagal menghapus data, silahkan coba kembali.");
      setIsError(true);
    }
  };

  if (isSubmit) {
    return <FormFunction title="" text={msg} push={home} status={200} />;
  }

  if (isError) {
    return <FormFunction title="" text={msg} push={home} status={500} />;
  }

  return (
    <>
      {/* <Hero title={title} subtitle={null} />
      <FormContainer>
        <form className="box">
          <ViewInput label={"ID Khotbah"} value={str.id} type={"text"} />
          <ViewInput label={"Kategori"} value={str.kategori} type={"text"} />
          <ViewInput label={"ID Gereja"} value={str.gereja} type={"text"} />
          <ViewInput label={"ID Pendeta"} value={str.pendeta} type={"text"} />
          <ViewInput label={"Judul Khotbah"} value={str.judul} type={"text"} />
          <ViewInput label={"Isi Khotbah"} value={str.isi} type={"text"} />
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Cover</label>
            </div>
            <div className="field-body">
              <div className="field">
                <img src={str.cover} alt={`Cover ${str.judul}`} />
              </div>
            </div>
          </div>
          <ViewInput label={"Featured"} value={str.highlight} type={"text"} />
          <div className="field mt-5">
            <FormDetailsButton type={"back"} loading={null} goto={home} />
            <FormDetailsButton
              type={"delete"}
              loading={isLoading}
              goto={null}
              script={Delete}
            />
          </div>
        </form>
      </FormContainer> */}
      <Descriptions
        title={`${pageTitle}: ${str.id}`}
        bordered
        column={{
          xxl: 4,
          xl: 3,
          lg: 3,
          md: 3,
          sm: 2,
          xs: 1,
        }}
        extra={
          <Space wrap>
            <Button
              type="primary"
              icon={<ArrowLeftOutlined />}
              onClick={() => history.push(home)}
            >
              Kembali
            </Button>
            <Button
              type="default"
              loading={isLoading}
              disabled={isLoading}
              danger
              icon={<DeleteOutlined />}
              onClick={() => Delete}
            >
              Hapus
            </Button>
          </Space>
        }
      >
        <Descriptions.Item label="ID Khotbah">{str.id}</Descriptions.Item>
        <Descriptions.Item label="Kategori">{str.kategori}</Descriptions.Item>
        <Descriptions.Item label="ID Gereja">{str.gereja}</Descriptions.Item>
        <Descriptions.Item label="ID Pendeta">{str.pendeta}</Descriptions.Item>
        <Descriptions.Item label="Judul">{str.judul}</Descriptions.Item>
        <Descriptions.Item label="Cover">
          <img src={str.cover} alt={`Cover ${str.judul}`} />
        </Descriptions.Item>
        <Descriptions.Item label="Featured">
          {str.highlight ? "YA" : "TIDAK"}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default ViewKhotbah;
