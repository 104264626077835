import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import axios from "axios";
import { apiUrl } from "../libs/vars";

let users = (set, get) => ({
  _hasHydrated: false,
  auth: "",
  token: "",
  dataState: [],
  fetchUserStore: async () => {
    const response = await axios.get(apiUrl + "/users", {
      headers: {
        Authorization: `Bearer ${get().token}`,
      },
    });
    set({ dataState: await response.data });
  },
  updateUserAuth: (key) => set({ auth: key }),
  updateUserStore: (data) => set({ dataState: data }),
  updateUserToken: (data) => set({ token: data }),
  viewSingleUser: (ids) => get().dataState.filter((item) => item.id === ids),
  clearUserStore: () => set({ dataState: [] }),
  setHasHydrated: (state) => {
    set({ _hasHydrated: state });
  },
});

users = persist(users, { name: "data-user" });

export const UserStore = create(devtools(users));
