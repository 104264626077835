import { Layout } from "antd";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Login from "./components/Login";
import Forms from "./components/Forms";
import Kategori from "./components/Kategori";
import Register from "./components/Register";
import Jemaat from "./pages/Jemaat";
import Gereja from "./pages/Gereja";
import Rayon from "./pages/Rayon";
import Wilayah from "./pages/Wilayah";
import Pendeta from "./pages/Pendeta";
import Berita from "./pages/Berita";
import Khotbah from "./pages/Khotbah";
import Jadwal from "./pages/Jadwal";
import KatPublikasi from "./pages/KatPublikasi";
import KatJadwal from "./pages/KatJadwal";
import User from "./pages/User";
import { Footer } from "./components/Footer";

function App() {
  const dataInside = [
    {
      path: "/",
      component: Dashboard,
      exact: true,
      withFooter: false,
    },
    {
      path: "/register",
      component: Register,
      exact: false,
      withFooter: false,
    },
    {
      path: "/login",
      component: Login,
      exact: false,
      withFooter: false,
    },
    {
      path: "/jemaat",
      component: Jemaat,
      exact: false,
      withFooter: true,
    },
    {
      path: "/gereja",
      component: Gereja,
      exact: false,
      withFooter: true,
    },
    {
      path: "/rayon",
      component: Rayon,
      exact: false,
      withFooter: true,
    },
    {
      path: "/wilayah",
      component: Wilayah,
      exact: false,
      withFooter: true,
    },
    {
      path: "/pendeta",
      component: Pendeta,
      exact: false,
      withFooter: true,
    },
    {
      path: "/berita",
      component: Berita,
      exact: false,
      withFooter: true,
    },
    {
      path: "/khotbah",
      component: Khotbah,
      exact: false,
      withFooter: true,
    },
    {
      path: "/jadwal",
      component: Jadwal,
      exact: false,
      withFooter: true,
    },
    {
      path: "/user",
      component: User,
      exact: true,
      withFooter: true,
    },
    {
      path: "/forms",
      component: Forms,
      exact: false,
      withFooter: false,
    },
    {
      path: "/kategori",
      component: Kategori,
      exact: false,
      withFooter: false,
    },
    {
      path: "/pubs/type",
      component: KatPublikasi,
      exact: true,
      withFooter: true,
    },
    {
      path: "/pubs/jadwal",
      component: KatJadwal,
      exact: true,
      withFooter: true,
    },
  ];
  return (
    <Layout hasSider>
      <BrowserRouter>
        <Switch>
          {dataInside.map((item, index) => (
            <Route key={index} exact={item.exact} path={item.path}>
              <item.component home={item.path} />
              {/* {item.withFooter ? <Footer /> : null} */}
            </Route>
          ))}
        </Switch>
      </BrowserRouter>
    </Layout>
  );
}

export default App;
