import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import { Button, Descriptions, Space } from "antd";
import { ArrowLeftOutlined, DeleteOutlined } from "@ant-design/icons";

import { apiGereja, keyGereja } from "../../libs/vars";
import { DataGereja } from "../../libs/context";
import {
  FormContainer,
  ViewImage,
  ViewInput,
} from "../../components/Forms.layout";
import Hero from "../../components/Hero";
import FormFunction from "../../components/Forms.function";
import { FormDetailsButton } from "../../components/Forms.buttons";

const ViewGereja = ({ title, home }) => {
  const history = useHistory();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isError, setIsError] = useState(false);
  const { pageTitle, gerejaState } = useContext(DataGereja);

  const [str, setStr] = useState({
    id: "",
    gembala: "",
    nama: "",
    lat: "",
    long: "",
    maps: "",
    jalan: "",
    kel: "",
    kec: "",
    kota: "",
    status: "",
    jumlah_ibadah: "",
    img_dpn: "",
    img_samp1: "",
    img_samp2: "",
    img_blkg: "",
    img_dlm_1: "",
    img_dlm_2: "",
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    // const data = JSON.parse(localStorage.getItem("dataGereja"));
    // const filterData = data.filter((item) => item.id === id);
    const filterData = gerejaState.filter((item) => item.id === id);
    if (filterData) {
      setStr({
        id: filterData.map((item) => item.id),
        gembala: filterData.map((item) => item.id_pdt),
        nama: filterData.map((item) => item.nm_grj),
        lat: filterData.map((item) => item.gps_lat),
        long: filterData.map((item) => item.gps_long),
        maps: filterData.map((item) => item.gmaps_link),
        jalan: filterData.map((item) => item.almt_jln),
        kel: filterData.map((item) => item.almt_kel),
        kec: filterData.map((item) => item.almt_kec),
        kota: filterData.map((item) => item.almt_kotakab),
        status: filterData.map((item) => item.stts_grj),
        jumlah_ibadah: filterData.map((item) => item.jum_ibdh),
        img_dpn: filterData.map((item) => item.img_dpn),
        img_samp1: filterData.map((item) => item.img_samp1),
        img_samp2: filterData.map((item) => item.img_samp2),
        img_blkg: filterData.map((item) => item.img_blkg),
        img_dlm_1: filterData.map((item) => item.img_dlm_1),
        img_dlm_2: filterData.map((item) => item.img_dlm_2),
      });
    }
  };

  const Delete = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const options = {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyGereja,
      },
    };
    const konfirmasi = window.prompt(
      "Isi dengan " + str.id + " untuk menghapus data."
    );
    if (konfirmasi === str.id) {
      await axios
        .delete(apiGereja + "/v1/" + id, options)
        .then(() => {
          localStorage.removeItem("singleGereja");
          setIsLoading(false);
          setIsSubmit(true);
          setMsg("Data berhasil dihapus!");
        })
        .catch((error) => {
          setIsLoading(false);
          setMsg("Gagal menghapus data. Coba kembali beberapa saat.");
          console.log(error);
          setIsError(true);
        });
    } else {
      setIsLoading(false);
      setMsg("Isian salah! Gagal menghapus data, silahkan coba kembali.");
      setIsError(true);
    }
  };

  if (isSubmit) {
    return <FormFunction title="" text={msg} push={home} status={200} />;
  }

  if (isError) {
    return <FormFunction title="" text={msg} push={home} status={500} />;
  }

  return (
    <>
      {/* <Hero title={`${pageTitle}: ${str.nama}`} subtitle={null} />
      <FormContainer>
        <form className="box">
          <ViewInput label={"ID Gereja"} value={str.id} type={"text"} />
          <ViewInput label={"ID Gembala"} value={str.gembala} type={"text"} />
          <ViewInput label={"Nama Gereja"} value={str.nama} type={"text"} />
          <ViewInput label={"Koordinat (Lat)"} value={str.lat} type={"text"} />
          <ViewInput
            label={"Koordinat (Long)"}
            value={str.long}
            type={"text"}
          />
          <ViewInput
            label={"Link Google Maps"}
            value={str.maps}
            type={"text"}
          />
          <ViewInput label={"Alamat: Jalan"} value={str.jalan} type={"text"} />
          <ViewInput
            label={"Alamat: Kelurahan"}
            value={str.kel}
            type={"text"}
          />
          <ViewInput
            label={"Alamat: Kecamatan"}
            value={str.kec}
            type={"text"}
          />
          <ViewInput
            label={"Alamat: Kota/Kabupaten"}
            value={str.kota}
            type={"text"}
          />
          <ViewInput label={"Status Gereja"} value={str.status} type={"text"} />
          <ViewInput
            label={"Jumlah Ibadah"}
            value={str.jumlah_ibadah}
            type={"text"}
          />
          <ViewImage label={"Foto Depan"} src={str.img_dpn} text={""} />
          <ViewImage label={"Foto Samping"} src={str.img_samp1} text={""} />
          <ViewImage label={"Foto Samping"} src={str.img_samp2} text={""} />
          <ViewImage label={"Foto Belakang"} src={str.img_blkg} text={""} />
          <ViewImage label={"Foto Interior"} src={str.img_dlm_1} text={""} />
          <ViewImage label={"Foto Interior"} src={str.img_dlm_2} text={""} />
          <div className="field mt-5">
            <FormDetailsButton type={"back"} loading={null} goto={home} />
            <FormDetailsButton
              type={"delete"}
              loading={isLoading}
              goto={null}
              script={Delete}
            />
          </div>
        </form>
      </FormContainer> */}
      <Descriptions
        title={`${pageTitle}: ${str.nama}`}
        bordered
        column={{
          xxl: 4,
          xl: 3,
          lg: 3,
          md: 3,
          sm: 2,
          xs: 1,
        }}
        extra={
          <Space wrap>
            <Button
              type="primary"
              icon={<ArrowLeftOutlined />}
              onClick={() => history.push(home)}
            >
              Kembali
            </Button>
            <Button
              type="default"
              loading={isLoading}
              disabled={isLoading}
              danger
              icon={<DeleteOutlined />}
              onClick={() => Delete}
            >
              Hapus
            </Button>
          </Space>
        }
      >
        <Descriptions.Item label="ID Gereja">{str.id}</Descriptions.Item>
        <Descriptions.Item label="ID Gembala">{str.gembala}</Descriptions.Item>
        <Descriptions.Item label="Nama Gereja">{str.nama}</Descriptions.Item>
        <Descriptions.Item label="Koordinat">
          {str.lat}, {str.long}
        </Descriptions.Item>
        <Descriptions.Item label="Google Maps">
          <a href={str.maps}>Link</a>
        </Descriptions.Item>
        <Descriptions.Item label="Alamat">
          {str.jalan} {str.kel} {str.kec}
        </Descriptions.Item>
        <Descriptions.Item label="Kab/Kota">{str.kota}</Descriptions.Item>
        <Descriptions.Item label="Pekerjaan">{str.pekerjaan}</Descriptions.Item>
        <Descriptions.Item label="Status Gereja">
          {str.status}
        </Descriptions.Item>
        <Descriptions.Item label="Jumlah Ibadah">
          {str.jumlah_ibadah}
        </Descriptions.Item>
        <Descriptions.Item label="Foto Depan">
          <img src={str.img_dpn} alt="Foto" />
        </Descriptions.Item>
        <Descriptions.Item label="Foto Samping">
          <img src={str.img_samp1} alt="Foto" />
          <img src={str.img_samp2} alt="Foto" />
        </Descriptions.Item>
        <Descriptions.Item label="Foto Belakang">
          <img src={str.img_blkg} alt="Foto" />
        </Descriptions.Item>
        <Descriptions.Item label="Foto Interior">
          <img src={str.img_dlm_1} alt="Foto" />
          <img src={str.img_dlm_2} alt="Foto" />
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default ViewGereja;
