export const appVer = "v0.10.1";
export const appName = "MYGPDI Backend";

export const apiUrl = "https://mygpdi-auth.deta.dev";
export const apiWilayah = "https://mygpdi-wilayah.deta.dev";
export const apiRayon = "https://mygpdi-rayon.deta.dev";
export const apiKatPubs = "https://mygpdi-katpubs.deta.dev";
export const apiSermon = "https://mygpdi-sermon.deta.dev";
export const apiNews = "https://mygpdi-news.deta.dev";
export const apiGereja = "https://mygpdi-gereja.deta.dev";
export const apiJemaat = "https://mygpdi-jemaat.deta.dev";
export const apiPendeta = "https://mygpdi-pendeta.deta.dev";
export const apiJadwal = "https://mygpdi-jadwal.deta.dev";
export const apiKatJadwal = "https://mygpdi-katjadwal.deta.dev";

export const keyWilayah = "tCXDFgrN.wN2qhqLawkkTsa5cwzUkkx-FETih5526U";
export const keyRayon = "xcs5TjCV.3vHQZ6ruzaXvJdSs5LszFX-bBcTnXcLJi";
export const keyKatPubs = "oNpo2NWn.mtuhW8fYucCAchmkLS516Q-CbYMXwgUNQ";
export const keySermon = "HBGQ8zR8.xJm7h19pH1eu7CEgQhxx3y-rFhvjRqQKw";
export const keyNews = "pKf3Lht5.m3gMkBckCHwsEFc53j9T5T-eCdabywveM";
export const keyGereja = "KrPbj7KE.pPkiRKu4Z2rWdDpg8h5Cp3-3QEnje8vsM";
export const keyJemaat = "AidgRkSh.TyrNa3Gf8KN2CugA5tG8MX-h22TkB4NxS";
export const keyPendeta = "Z9dvEFrB.trmgJhjZHreWpWFeWmevGo-JJgWALCnzi";
export const keyJadwal = "3YydQGXU.oxLc3CtGkCq7R1W4FhLhRT-oARJeiKQDT";
export const keyKatJadwal = "9mnqac28.wi7UrnBx5WVUA9NcgzmDJm-LtxX3iqcTv";
