/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useMemo } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
// import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import { Column, HeaderCell, Cell } from "rsuite-table";
import { Layout, Space, Table, Button, Typography, theme, Avatar } from "antd";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { apiJemaat, apiRayon, apiUrl, keyJemaat, keyRayon } from "../libs/vars";
import { DataJemaat } from "../libs/context";
import { JemaatStore } from "../state/Jemaat";
import Topbar from "../components/Topbar";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import ViewJemaat from "./components/Jemaat.details";
import { IndexContainer } from "../components/Index.layout";
import { Footer } from "../components/Footer";
import "rsuite-table/dist/css/rsuite-table.css";

const { Content } = Layout;
const { Title } = Typography;

const JemaatStateSelector = (state) => state.dataState;

const Jemaat = ({ home }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const pageTitle = "Jemaat";
  const { path } = useRouteMatch();
  const [msg, setMessage] = useState("");
  // const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const [jemaat, setJemaat] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const jemaatState = JemaatStore(JemaatStateSelector);
  const { updateJemaatStore } = JemaatStore();

  useEffect(() => {
    refreshToken();
    // preloadData();
    // getJemaat();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(apiUrl + "/auth/token");
      setToken(response.data.accessToken);
      const decoded = jwt_decode(response.data.accessToken);
      // setName(decoded.name);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        history.push("/");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(apiUrl + "/auth/token");
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        // setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const preloadData = async () => {
    setIsLoading(true);
    try {
      const optionJemaat = {
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "X-API-Key": keyJemaat,
        },
      };
      const optionRayon = {
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "X-API-Key": keyRayon,
        },
      };
      const dataRayon = await axiosJWT.get(apiRayon + "/v1", optionRayon);
      localStorage.setItem("dataRayon", JSON.stringify(dataRayon.data));
      const dataJemaat = await axiosJWT.get(apiJemaat + "/v1", optionJemaat);
      localStorage.setItem("dataJemaat", JSON.stringify(dataJemaat.data));
      setJemaat(dataJemaat.data);
      setIsLoading(false);
    } catch (error) {
      if (error) {
        setIsLoading(false);
        setMessage("Gagal mengambil data. Cobalah beberapa saat lagi!");
        // alert("Gagal mengambil data. Cobalah beberapa saat lagi!");
      }
    }
  };

  const getJemaat = async () => {
    setIsLoading(true);
    try {
      const options = {
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "X-API-Key": keyJemaat,
        },
      };
      const response = await axiosJWT.get(apiJemaat + "/v1", options);
      // setJemaat(response);
      updateJemaatStore(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setMessage("Gagal mengambil data. Cobalah beberapa saat lagi!");
      // alert("Gagal memuat data Jemaat terbaru. Cobalah beberapa saat lagi!");
    }
  };

  const contextValue = useMemo(
    () => ({ jemaatState, getJemaat, setIsLoading, isLoading, pageTitle }),
    [jemaatState, getJemaat]
  );

  return (
    <>
      {/* <Navbar /> */}
      <Sidebar />
      <Layout>
        <Topbar />
        <Content style={{ margin: "24px 16px 0" }}>
          <Title level={1} style={{ margin: "16px 0" }}>
            {pageTitle}
          </Title>
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
            }}
          >
            <Switch>
              <DataJemaat.Provider value={contextValue}>
                <Route exact path={`${path}`}>
                  <Home msg={msg} />
                </Route>
                {/* <Route path={`${path}/edit/:id`}>
          <EditRayon home={home} />
        </Route> */}
                <Route path={`${path}/details/:id`}>
                  <ViewJemaat title={pageTitle} home={home} />
                </Route>
              </DataJemaat.Provider>
            </Switch>
          </div>
        </Content>
        {/* <Footer style={{ textAlign: "center" }}>MYGPDI Backend ©2023</Footer> */}
        {/* <Footer /> */}
      </Layout>
    </>
  );
};

const Home = () => {
  const history = useHistory();
  const { pageTitle, isLoading, getJemaat } = useContext(DataJemaat);

  function goTo() {
    history.push("/forms/jemaat");
  }

  return (
    <>
      {/* <Hero title={pageTitle} subtitle={null} /> */}
      <IndexContainer add={goTo} loading={isLoading} refresh={getJemaat}>
        <div className="table-container">
          {/* <Tabel /> */}
          <TabelAlt />
        </div>
      </IndexContainer>
    </>
  );
};

const ActionButton = () => {
  return (
    <div className="dropdown">
      <div className="dropdown-trigger">
        <button
          className="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu3"
        >
          <span>Tindakan</span>
          <span className="icon is-small">
            <i className="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu3" role="menu">
        <div className="dropdown-content">
          <a href="#" className="dropdown-item">
            Edit
          </a>
          <a href="#" className="dropdown-item">
            Hapus
          </a>
          {/* <hr className="dropdown-divider" /> */}
        </div>
      </div>
    </div>
  );
};

const Tabel = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { getJemaat, jemaatState, isLoading } = useContext(DataJemaat);

  async function Delete(key) {
    setLoading(true);
    const options = {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyJemaat,
      },
    };
    const konfirmasi = window.confirm("Yakin mau menghapus ID: " + key + "?");
    if (konfirmasi) {
      await axios
        .delete(apiJemaat + "/v1/" + key, options)
        .then((response) => {
          alert("Data berhasil dihapus.");
          setLoading(false);
          getJemaat();
          setMessage(response);
        })
        .catch((error) => {
          setLoading(false);
          setMessage(error);
        });
    } else {
      setLoading(false);
    }
  }

  const ImageCell = ({ rowData, dataKey, ...rest }) => (
    <Cell {...rest}>
      <img src={rowData[dataKey]} width="50" />
    </Cell>
  );

  return (
    <Table
      data={jemaatState}
      loading={isLoading}
      autoHeight
      cellBordered
      wordWrap="break-word"
    >
      <Column width={50} fixed resizable>
        <HeaderCell>No</HeaderCell>
        <Cell>
          {(rows, index) => {
            return index + 1;
          }}
        </Cell>
      </Column>
      <Column width={100} sortable fixed resizable>
        <HeaderCell>ID Jemaat</HeaderCell>
        <Cell dataKey="id" />
      </Column>
      <Column width={200} sortable fixed flexGrow={1}>
        <HeaderCell>Nama Lengkap</HeaderCell>
        <Cell>
          {(rowData) => {
            return `${rowData.nm_depan} ${rowData.nm_tengah} ${rowData.nm_belakang}`;
          }}
        </Cell>
      </Column>
      <Column width={200} fixed flexGrow={2}>
        <HeaderCell>Kode Gereja</HeaderCell>
        <Cell dataKey="id_grj" />
      </Column>
      <Column width={200} fixed flexGrow={2}>
        <HeaderCell>Rayon</HeaderCell>
        <Cell dataKey="id_rayon" />
      </Column>
      <Column width={200} fixed flexGrow={2}>
        <HeaderCell>Kelamin</HeaderCell>
        <Cell dataKey="jns_kel" />
      </Column>
      <Column width={200} fixed flexGrow={2}>
        <HeaderCell>No HP</HeaderCell>
        <Cell dataKey="nmr_hp" />
      </Column>
      {/* <Column width={200} fixed flexGrow={2}>
        <HeaderCell>Tanggal Lahir</HeaderCell>
        <Cell dataKey="tgl_lhr" />
      </Column> */}
      {/* <Column width={200} fixed flexGrow={2}>
        <HeaderCell>Kota/Kab</HeaderCell>
        <Cell dataKey="almt_kotakab" />
      </Column> */}
      <Column fixed flexGrow={3}>
        <HeaderCell>Foto</HeaderCell>
        <ImageCell dataKey="img_jemaat" />
      </Column>
      <Column width={250} fixed>
        <HeaderCell>Tindakan</HeaderCell>
        <Cell>
          {(rows) => {
            return (
              <Space wrap>
                <Button
                  type="primary"
                  size="small"
                  disabled={loading}
                  icon={<EyeOutlined />}
                  onClick={() => history.push(`${path}/details/${rows.id}`)}
                >
                  Detail
                </Button>
                <Button
                  size="small"
                  disabled={loading}
                  icon={<EditOutlined />}
                  onClick={() => history.push(`${path}/edit/${rows.id}`)}
                >
                  Edit
                </Button>
                <Button
                  type="dashed"
                  size="small"
                  loading={loading}
                  disabled={loading}
                  icon={<DeleteOutlined />}
                  danger
                  onClick={() => Delete(rows.id)}
                >
                  Hapus
                </Button>
              </Space>
            );
          }}
        </Cell>
      </Column>
    </Table>
  );
};

function TabelAlt() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { getJemaat, jemaatState, isLoading } = useContext(DataJemaat);

  async function Delete(key) {
    setLoading(true);
    const options = {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyJemaat,
      },
    };
    const konfirmasi = window.confirm("Yakin mau menghapus ID: " + key + "?");
    if (konfirmasi) {
      await axios
        .delete(apiJemaat + "/v1/" + key, options)
        .then((response) => {
          alert("Data berhasil dihapus.");
          setLoading(false);
          getJemaat();
          setMessage(response);
        })
        .catch((error) => {
          setLoading(false);
          setMessage(error);
        });
    } else {
      setLoading(false);
    }
  }

  const kolom = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (rows) => <Link to={`${path}/details/${rows}`}>{rows}</Link>,
    },
    {
      title: "A",
      dataIndex: "img_jemaat",
      key: "avatar",
      responsive: ["md"],
      render: (url) => {
        <Avatar size={36} src={url} icon={<UserOutlined />} />;
      },
    },
    {
      title: "Nama",
      dataIndex: "nm_depan",
      key: "nama",
      responsive: ["md"],
      render: (text) => {
        <Title>{text}</Title>;
      },
    },
    {
      title: "Gereja",
      dataIndex: "id_grj",
      key: "gereja",
      responsive: ["md"],
    },
    {
      title: "Kelamin",
      dataIndex: "jns_kel",
      key: "kelamin",
      responsive: ["md"],
    },
    {
      title: "No HP",
      dataIndex: "nmr_hp",
      key: "phone",
      responsive: ["md"],
    },
    {
      title: "Tindakan",
      key: "action",
      render: (_, rows) => (
        <Space size="middle" wrap>
          {/* <Button
            type="primary"
            size="small"
            disabled={loading}
            icon={<EyeOutlined />}
            onClick={() => history.push(`${path}/details/${rows.id}`)}
          >
            Detail
          </Button> */}
          <Button
            size="small"
            disabled={loading}
            icon={<EditOutlined />}
            onClick={() => history.push(`${path}/edit/${rows.id}`)}
          >
            Edit
          </Button>
          <Button
            type="dashed"
            size="small"
            loading={loading}
            disabled={loading}
            icon={<DeleteOutlined />}
            danger
            onClick={() => Delete(rows.id)}
          >
            Hapus
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Table
      dataSource={jemaatState}
      loading={isLoading}
      columns={kolom}
      pagination={{ pageSize: 25 }}
    ></Table>
  );
}

export default Jemaat;
