import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { apiPendeta, keyPendeta } from "../libs/vars";
import FormFunction from "./Forms.function";
import { FormAddButton } from "./Forms.buttons";
import { AddInput, FormContainer } from "./Forms.layout";
import Hero from "./Hero";

const AddPendeta = ({ pageTitle }) => {
  const home = "/pendeta";
  // const pageTitle = "Pendeta";
  const [isLoading, setIsLoading] = useState(false);
  const [idPendeta, setIdPendeta] = useState("");
  const [idJemaat, setIdJemaat] = useState("");
  const [namaPendeta, setNamaPendeta] = useState("");
  const [statusPendeta, setStatusPendeta] = useState("");
  const [fotoPendeta, setFotoPendeta] = useState("");
  const [pendidikanTerakhir, setPendidikan] = useState("");
  const [msg, setMsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isError, setIsError] = useState(false);
  const history = useHistory();

  const Register = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const payload = {
      id: idPendeta,
      id_jem: idJemaat,
      nm_pdt: namaPendeta,
      stat_pdt: statusPendeta,
      img_pdt: fotoPendeta,
      his_pendidikan: pendidikanTerakhir,
    };
    const options = {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyPendeta,
      },
    };
    // try {
    //   await axios.post(apiPendeta + "/v1", payload, options);
    //   setIsLoading(false);
    //   history.push(home);
    // } catch (error) {
    //   if (error.response) {
    //     setIsLoading(false);
    //     setMsg(error.response.data.msg);
    //   }
    // }
    await axios
      .post(apiPendeta + "/v1", payload, options)
      .then(() => {
        setIsLoading(false);
        setMsg("Data tersimpan!");
        setIsSubmit(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setMsg(error.message);
        setIsError(true);
      });
  };

  if (isSubmit) {
    return <FormFunction text={msg} push={home} status={200} />;
  }

  if (isError) {
    return (
      <FormFunction
        text={"Gagal menyimpan data. Coba kembali beberapa saat. (" + msg + ")"}
        push={home}
        status={500}
      />
    );
  }

  return (
    <>
      {/* <Hero title={pageTitle} subtitle={null} /> */}
      <FormContainer>
        <form onSubmit={Register} className="box">
          <p className="has-text-centered">{msg}</p>
          <AddInput
            label={"ID Pendeta"}
            value={idPendeta}
            change={(e) => setIdPendeta(e.target.value)}
            type={"text"}
            isRequired={true}
          />
          <AddInput
            label={"ID Jemaat"}
            value={idJemaat}
            change={(e) => setIdJemaat(e.target.value)}
            type={"text"}
            isRequired={true}
          />
          <AddInput
            label={"Nama Pendeta"}
            value={namaPendeta}
            change={(e) => setNamaPendeta(e.target.value)}
            type={"text"}
            isRequired={true}
          />
          <AddInput
            label={"Status Pendeta"}
            value={statusPendeta}
            change={(e) => setStatusPendeta(e.target.value)}
            type={"text"}
            isRequired={true}
          />
          <div className="file has-name mt-5">
            <label className="file-label">
              <input
                className="file-input"
                type="file"
                onChange={(e) => setFotoPendeta(e.target.value)}
                value={fotoPendeta}
              />
              <span className="file-cta">
                <span className="file-icon">
                  <i className="fas fa-upload"></i>
                </span>
                <span className="file-label">Unggah foto diri…</span>
              </span>
              <span className="file-name"></span>
            </label>
          </div>
          <AddInput
            label={"Pendidikan Terakhir"}
            value={pendidikanTerakhir}
            change={(e) => setPendidikan(e.target.value)}
            type={"text"}
            isRequired={false}
          />
          <div className="field mt-5">
            <FormAddButton type={"save"} loading={isLoading} goto={null} />
            <FormAddButton type={"back"} loading={null} goto={home} />
          </div>
        </form>
      </FormContainer>
    </>
  );
};

export default AddPendeta;
