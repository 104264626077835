import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import axios from "axios";
import { apiSermon, keySermon } from "../libs/vars";

let khotbah = (set, get) => ({
  _hasHydrated: false,
  auth: "",
  dataState: [],
  fetchKhotbahStore: async () => {
    const response = await axios.get(apiSermon + "/v1", {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keySermon,
      },
    });
    set({ dataState: await response.data });
  },
  updateKhotbahAuth: (key) => set({ auth: key }),
  updateKhotbahStore: (data) => set({ dataState: data }),
  viewSingleKhotbah: (ids) => get().dataState.filter((item) => item.id === ids),
  clearKhotbahStore: () => set({ dataState: [] }),
  setHasHydrated: (state) => {
    set({ _hasHydrated: state });
  },
});

khotbah = persist(khotbah, {
  name: "data-khotbah",
  onRehydrateStorage: () => (state) => {
    state.setHasHydrated(true);
  },
});

export const KhotbahStore = create(devtools(khotbah));
