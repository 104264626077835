import { Button, Space } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";

export const IndexContainer = ({ add, loading, refresh, children }) => {
  return (
    <>
      <Space size={16} style={{ marginBottom: "16px" }} wrap>
        <Button
          type="primary"
          icon={<PlusCircleFilled />}
          disabled={loading}
          onClick={add}
        >
          Tambah Data
        </Button>
        <Button type="default" loading={loading} onClick={refresh}>
          Refresh
        </Button>
      </Space>
      {children}
    </>
  );
};
