import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { apiUrl } from "../libs/vars";
import FormFunction from "./Forms.function";
import { FormAddButton } from "./Forms.buttons";
import { AddInput, FormContainer } from "./Forms.layout";
import Hero from "./Hero";

const AddUser = ({ pageTitle }) => {
  const home = "/user";
  // const pageTitle = "Wilayah";
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState("");
  const [nama, setNama] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isError, setIsError] = useState(false);
  // const history = useHistory();

  const Register = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const payload = {
      id: id,
      name: nama,
      email: email,
    };
    const options = {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        // "X-API-Key": keyWilayah,
      },
    };
    await axios
      .post(apiUrl + "/users", payload, options)
      .then(() => {
        setIsLoading(false);
        setMsg("Data tersimpan!");
        setIsSubmit(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setMsg(error.message);
        setIsError(true);
      });
  };

  if (isSubmit) {
    return <FormFunction text={msg} push={home} status={200} />;
  }

  if (isError) {
    return (
      <FormFunction
        text={"Gagal menyimpan data. Coba kembali beberapa saat. (" + msg + ")"}
        push={home}
        status={500}
      />
    );
  }

  return (
    <>
      {/* <Hero title={pageTitle} subtitle={null} /> */}
      <FormContainer>
        <form onSubmit={Register} className="box">
          <p className="has-text-centered">{msg}</p>
          <AddInput
            label={"ID User"}
            value={id}
            change={(e) => setId(e.target.value)}
            type={"text"}
            isRequired={true}
          />
          <AddInput
            label={"Nama"}
            value={nama}
            change={(e) => setNama(e.target.value)}
            type={"text"}
            isRequired={false}
          />
          <AddInput
            label={"Email"}
            value={email}
            change={(e) => setEmail(e.target.value)}
            type={"text"}
            isRequired={true}
          />
          <div className="field mt-5">
            <FormAddButton type={"save"} loading={isLoading} goto={null} />
            <FormAddButton type={"back"} loading={null} goto={home} />
          </div>
        </form>
      </FormContainer>
    </>
  );
};

export default AddUser;
