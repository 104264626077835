import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import axios from "axios";
import { apiUrl } from "../libs/vars";

let username = (set) => ({
  auth: "",
  dataState: [],
  updateUsernameAuth: (key) => set({ auth: key }),
  updateUsername: (name) => set(() => ({ dataState: name })),
  fetch: async () => {
    const response = await axios.get(apiUrl + "/users");
    set({ dataState: await response.name });
  },
});

username = persist(username, { name: "user-name" });

export const usernameStore = create(devtools(username));
