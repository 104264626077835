import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import axios from "axios";
import { apiKatJadwal, keyKatJadwal } from "../libs/vars";

let katJadwal = (set, get) => ({
  _hasHydrated: false,
  auth: "",
  dataState: [],
  fetchKatJadwalStore: async () => {
    const response = await axios.get(apiKatJadwal + "/v1", {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyKatJadwal,
      },
    });
    set({ dataState: await response.data });
  },
  updateKatJadwalAuth: (key) => set({ auth: key }),
  updateKatJadwalStore: (data) => set({ dataState: data }),
  viewSingleKatJadwal: (ids) =>
    get().dataState.filter((item) => item.id === ids),
  clearKatJadwalStore: () => set({ dataState: [] }),
  setHasHydrated: (state) => {
    set({ _hasHydrated: state });
  },
});

katJadwal = persist(katJadwal, { name: "data-kat-jadwal" });

export const KatJadwalStore = create(devtools(katJadwal));
