import { Switch, Route, useRouteMatch } from "react-router-dom";
import AddJemaat from "./Jemaat.add";
import AddGereja from "./Gereja.add";
import AddRayon from "./Rayon.add";
import AddWilayah from "../pages/components/Wilayah.add";
import AddPendeta from "./Pendeta.add";
import AddBerita from "../pages/components/Berita.add";
import AddKhotbah from "../pages/components/Khotbah.add";
import AddJadwal from "../pages/components/Jadwal.add";
import AddUser from "./User.add";
import Navbar from "./Navbar";
import { Footer } from "./Footer";

export default function Forms() {
  const { path } = useRouteMatch();
  const links = [
    {
      name: "berita",
      component: AddBerita,
    },
    {
      name: "gereja",
      component: AddGereja,
    },
    {
      name: "jemaat",
      component: AddJemaat,
    },
    {
      name: "khotbah",
      component: AddKhotbah,
    },
    {
      name: "jadwal",
      component: AddJadwal,
    },
    {
      name: "pendeta",
      component: AddPendeta,
    },
    {
      name: "rayon",
      component: AddRayon,
    },
    {
      name: "wilayah",
      component: AddWilayah,
    },
    {
      name: "user",
      component: AddUser,
    },
  ];

  return (
    <Switch>
      {links.map((link, index) => (
        <Route key={index} path={`${path}/${link.name}`}>
          {/* <Navbar /> */}
          <link.component pageTitle={link.name} />
          {/* <Footer /> */}
        </Route>
      ))}
    </Switch>
  );
}
