import {
  AppstoreOutlined,
  LogoutOutlined,
  PieChartOutlined,
  SettingOutlined,
  MailOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import axios from "axios";
import { Layout, Menu, Tag, Button, Typography } from "antd";
import { useHistory } from "react-router-dom";

import { FooterSimple } from "./Footer";
import { apiUrl, appName, appVer } from "../libs/vars";
import "../styles/theme.css";

const { Sider, Footer } = Layout;
const { Title } = Typography;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem(<a href="/">Home</a>, "1", <PieChartOutlined />),
  getItem("Organisasi", "suborg", <MailOutlined />, [
    getItem(<a href="/jemaat">Jemaat</a>, "2"),
    getItem(<a href="/gereja">Gereja</a>, "3"),
    getItem(<a href="/pendeta">Pendeta</a>, "4"),
    {
      type: "divider",
    },
    getItem(<a href="/rayon">Rayon</a>, "5"),
    getItem(<a href="/wilayah">Wilayah</a>, "6"),
  ]),
  getItem("Publikasi", "subpub", <AppstoreOutlined />, [
    getItem(<a href="/jadwal">Jadwal</a>, "7"),
    getItem(<a href="/berita">Berita</a>, "8"),
    getItem(<a href="/khotbah">Khotbah</a>, "9"),
    getItem("Kategori", "sub3", null, [
      getItem(<a href="/pubs/type">Publikasi</a>, "10"),
      getItem(<a href="/pubs/jadwal">Jadwal</a>, "11"),
    ]),
  ]),
  getItem("Admin", "submin", <SettingOutlined />, [
    getItem(<a href="/user">User</a>, "12"),
  ]),
];

// submenu keys of first level
const rootSubmenuKeys = ["suborg", "subpub", "submin"];

const Sidebar = () => {
  const [openKeys, setOpenKeys] = useState(["suborg"]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  function hapusStorage() {
    localStorage.clear();
  }

  const Logout = async () => {
    setIsLoading(true);
    try {
      await axios.delete(apiUrl + "/auth/logout");
      hapusStorage();
      setIsLoading(false);
      history.push("/login");
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      //   style={{
      //     overflow: "auto",
      //     height: "100vh",
      //     position: "fixed",
      //     left: 0,
      //     top: 0,
      //     bottom: 0,
      //   }}
      //   onBreakpoint={(broken) => {
      //     console.log(broken);
      //   }}
      //   onCollapse={(collapsed, type) => {
      //     console.log(collapsed, type);
      //   }}
    >
      <div style={{ margin: "24px 0", textAlign: "center" }}>
        <Title level={3} style={{ color: "white" }}>
          {appName.toUpperCase()}
        </Title>
        <Tag color="geekblue">{appVer}</Tag>
      </div>
      <Menu
        // defaultSelectedKeys={["1"]}
        defaultOpenKeys={["1"]}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        mode="inline"
        theme="dark"
        // inlineCollapsed={collapsed}
        items={items}
      />
      <div style={{ textAlign: "center", margin: "24px 0" }}>
        <Button
          type="default"
          icon={<LogoutOutlined />}
          loading={isLoading}
          onClick={Logout}
          style={{ marginBottom: "24px" }}
          danger
          ghost
        >
          Logout
        </Button>
        <Footer style={{ backgroundColor: "#001529", color: "white" }}>
          <p>&copy; 2022-2023 GPdI Sulut.</p>
        </Footer>
      </div>
    </Sider>
  );
};

export default Sidebar;
