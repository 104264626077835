import { appName, appVer } from "../libs/vars";

export const Footer = () => {
  return (
    <footer className="footer has-background-white">
      <div className="content has-text-centered">
        <div className="tags has-addons is-justify-content-center">
          <span className="tag is-dark">&copy; 2022-2023 GPdI Sulut</span>
          <span className="tag is-light">{appVer}</span>
        </div>
      </div>
    </footer>
  );
};

export const FooterSimple = () => {
  return (
    <div className="content has-text-centered is-small">
      <p>
        {appName} {appVer}
      </p>
      {/* <div className="tags has-addons is-justify-content-center">
        <span className="tag is-light">{appName}</span>
        <span className="tag is-info">{appVer}</span>
      </div> */}
    </div>
  );
};
