import React, { useState, Fragment } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Button,
  Checkbox,
  Form,
  Input,
  Layout,
  Row,
  Tag,
  theme,
} from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

import { FooterSimple } from "./Footer";
import { apiUrl, appName, appVer } from "../libs/vars";
import "../styles/theme.css";

const { Content, Footer } = Layout;

const Login = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const history = useHistory();

  const Auth = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios.post(apiUrl + "/auth/login", {
        email: email,
        password: password,
      });
      setIsLoading(false);
      history.push("/");
    } catch (error) {
      if (error.response) {
        setIsLoading(false);
        setMsg(error.response.data.msg);
        setShowAlert(true);
      }
    }
  };

  const OnFinish = async () => {
    setIsLoading(true);
    await axios
      .post(apiUrl + "/auth/login", {
        email: email,
        password: password,
      })
      .then(() => {
        setIsLoading(false);
        history.push("/");
      })
      .catch(function (error) {
        setIsLoading(false);
        setMsg(error.msg);
      });
  };

  const OnFinishFailed = () => {
    setShowAlert(true);
  };

  return (
    // <section className="hero has-background-grey-light is-fullheight is-fullwidth">
    //   <div className="hero-body">
    //     <div className="container">
    //       <div className="content has-text-centered">
    //         <h1 className="has-text-weight-bold title is-2">Login</h1>
    //       </div>
    //       <div className="columns is-centered">
    //         <div className="column is-4-desktop">
    //           <form onSubmit={Auth} className="box">
    //             <p className="has-text-centered">{msg}</p>
    //             <div className="field mt-5">
    //               <label className="label">Email or Username</label>
    //               <div className="controls">
    //                 <input
    //                   type="text"
    //                   className="input"
    //                   placeholder="Username"
    //                   value={email}
    //                   onChange={(e) => setEmail(e.target.value)}
    //                   required="required"
    //                 />
    //               </div>
    //             </div>
    //             <div className="field mt-5">
    //               <label className="label">Password</label>
    //               <div className="controls">
    //                 <input
    //                   type="password"
    //                   className="input"
    //                   placeholder="******"
    //                   value={password}
    //                   onChange={(e) => setPassword(e.target.value)}
    //                   required="required"
    //                 />
    //               </div>
    //             </div>
    //             <div className="field mt-5">
    //               <button
    //                 className={`button is-info is-fullwidth ${
    //                   isLoading ? "is-loading" : ""
    //                 }`}
    //                 disabled={isLoading}
    //               >
    //                 Login
    //               </button>
    //             </div>
    //           </form>
    //         </div>
    //       </div>
    //       <FooterSimple />
    //     </div>
    //   </div>
    // </section>
    // <Layout>
    //   <Content style={{ padding: "0 50px" }}>
    //     <div
    //       className="site-layout-content"
    //       style={{ background: colorBgContainer }}
    //     >
    <Fragment>
      <section className="form">
        <div className="logo">
          <img src="/logo-gpdi.png" alt="logo" />
          {/* <span>Login</span> */}
        </div>
        {showAlert ? (
          <Alert message={msg} type="error" showIcon closable />
        ) : null}
        <Form
          name="login-form"
          className="login-form"
          // labelCol={{ span: 8 }}
          // wrapperCol={{ span: 16 }}
          // style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          size="large"
          onFinish={OnFinish}
          onFinishFailed={OnFinishFailed}
          // onSubmit={() => Auth()}
          autocomplete="off"
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Email harus diisi!",
              },
              {
                pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/,
                message: "Your Email is invalid!",
              },
            ]}
            hasFeedback
          >
            <Input
              placeholder="Email"
              prefix={<UserOutlined className="site-form-item-icon" />}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: "Password harus diisi!" },
              { min: 4 },
            ]}
          >
            <Input.Password
              placeholder="Password"
              prefix={<LockOutlined className="site-form-item-icon" />}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>

          {/* <Form.Item
              name="remember"
              valuePropName="checked"
              wrapperCol={{ offset: 8, span: 16 }}
            >
              <Checkbox>Ingat saya</Checkbox>
            </Form.Item> */}
          <Form.Item>
            <Button
              type="primary"
              disabled={isLoading}
              loading={isLoading}
              htmlType="submit"
              className="login-form-button"
              block
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      </section>
      <section className="footer">
        {appName} <Tag color="geekblue">{appVer}</Tag>
      </section>
    </Fragment>
    //     </div>
    //   </Content>
    // </Layout>
  );
};

export default Login;
