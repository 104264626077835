import React from "react";

export const FormContainer = ({ children }) => {
  return (
    <section className="hero has-background-grey-light is-fullheight is-fullwidth">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-half-desktop">{children}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const ViewInput = ({ label, value, type }) => {
  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label">{label}</label>
      </div>
      <div className="field-body">
        <div className="field">
          <p className="control">
            {type === "text" && (
              <input
                type="text"
                className="input is-static"
                value={value}
                readonly
              />
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export const ViewImage = ({ label, src, text }) => {
  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label">{label}</label>
      </div>
      <div className="field-body">
        <div className="field">
          <img src={src} alt={text} />
        </div>
      </div>
    </div>
  );
};

export const EditInput = ({ label, value, type, change }) => {
  return (
    <div className="field mt-5">
      <label className="label">{label}</label>
      <div className="controls">
        {type === "text" && (
          <input
            type="text"
            className="input"
            placeholder=""
            defaultValue={value}
            onChange={change}
          />
        )}
      </div>
    </div>
  );
};

export const AddInput = ({ label, value, type, change, isRequired }) => {
  return (
    <div className="field mt-5">
      <label className="label">{label}</label>
      <div className="controls">
        {type === "text" && (
          <input
            type="text"
            className="input"
            placeholder=""
            value={value}
            onChange={change}
            required={isRequired ? "required" : ""}
          />
        )}
        {type === "email" && (
          <input
            type="email"
            className="input"
            placeholder=""
            value={value}
            onChange={change}
            required={isRequired ? "required" : ""}
          />
        )}
      </div>
    </div>
  );
};
