import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Layout, Button, Avatar, Space, Typography, theme } from "antd";

import { apiUrl } from "../libs/vars";
import { usernameStore } from "../state/userName";
import "../styles/theme.css";

const { Header } = Layout;
const { Title } = Typography;

// Prevent re-render or using useCallback
const nameselector = (state) => state.dataState;

const Topbar = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [isLoading, setIsLoading] = useState(false);
  const name = usernameStore(nameselector);
  const history = useHistory();

  function hapusStorage() {
    localStorage.clear();
  }

  const Logout = async () => {
    setIsLoading(true);
    try {
      await axios.delete(apiUrl + "/auth/logout");
      hapusStorage();
      setIsLoading(false);
      history.push("/login");
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <Header style={{ padding: "0 24px", background: colorBgContainer }}>
      <Space size={16} align="center">
        <Avatar
          size={36}
          style={{ backgroundColor: "#001529" }}
          icon={<UserOutlined />}
        />
        <Title level={5} style={{ marginBottom: 0 }}>
          {name}
        </Title>
        {/* <Button
          type="default"
          icon={<LogoutOutlined />}
          loading={isLoading}
          onClick={Logout}
          danger
          ghost
        >
          Logout
        </Button> */}
      </Space>
    </Header>
  );
};

export default Topbar;
