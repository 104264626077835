import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import axios from "axios";
import { apiKatPubs, keyKatPubs } from "../libs/vars";

let katPublikasi = (set, get) => ({
  _hasHydrated: false,
  auth: "",
  dataState: [],
  fetchKatPublikasiStore: async () => {
    const response = await axios.get(apiKatPubs + "/v1", {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyKatPubs,
      },
    });
    set({ dataState: await response.data });
  },
  updateKatPublikasiAuth: (key) => set({ auth: key }),
  updateKatPublikasiStore: (data) => set({ dataState: data }),
  viewSingleKatPublikasi: (ids) =>
    get().dataState.filter((item) => item.id === ids),
  clearKatPublikasiStore: () => set({ dataState: [] }),
  setHasHydrated: (state) => {
    set({ _hasHydrated: state });
  },
});

katPublikasi = persist(katPublikasi, { name: "data-kat-publikasi" });

export const KatPublikasiStore = create(devtools(katPublikasi));
