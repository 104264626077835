import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import {
  apiGereja,
  apiJadwal,
  apiKatJadwal,
  keyGereja,
  keyJadwal,
  keyKatJadwal,
} from "../../libs/vars";
import { DataKatJadwal, DataGereja } from "../../libs/context";
import FormFunction from "../../components/Forms.function";
import { FormAddButton } from "../../components/Forms.buttons";
import { AddInput, FormContainer } from "../../components/Forms.layout";
import Hero from "../../components/Hero";

const AddJadwal = ({ pageTitle }) => {
  const home = "/jadwal";
  // const pageTitle = "Jadwal";
  const { katJadwalState } = useContext(DataKatJadwal);
  const { gerejaState } = useContext(DataGereja);
  const [isLoading, setIsLoading] = useState(false);
  const [idJadwal, setIdJadwal] = useState("");
  const [katJadwal, setKategori] = useState("");
  const [idGereja, setIdGereja] = useState("");
  const [namaIbadah, setNamaIbadah] = useState("");
  const [tglIbadah, setTglIbadah] = useState("");
  const [jamIbadah, setJamIbadah] = useState("");
  const [live, setLive] = useState("");
  const [dataKatJadwal, setDataKatJadwal] = useState([]);
  const [loadingKatJadwal, setLoadingKatJadwal] = useState(false);
  const [dataGereja, setDataGereja] = useState([]);
  const [loadingGereja, setLoadingGereja] = useState(false);
  const [msg, setMsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isError, setIsError] = useState(false);
  const history = useHistory();

  // useEffect(() => {
  //   Promise.all([getPublikasi(), getGereja()]);
  //   getPublikasi();
  //   getGereja();
  // }, []);

  const Register = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const payload = {
      id: idJadwal,
      id_grj: idGereja,
      kategori_jadwal: katJadwal,
      nama_ibadah: namaIbadah,
      tgl_ibadah: tglIbadah,
      jam_ibadah: jamIbadah,
      live: live,
    };
    const options = {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyJadwal,
      },
    };
    await axios
      .post(apiJadwal + "/v1", payload, options)
      .then(() => {
        setIsLoading(false);
        setMsg("Data tersimpan!");
        setIsSubmit(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setMsg(error.message);
        setIsError(true);
      });
  };

  if (isSubmit) {
    return <FormFunction text={msg} push={home} status={200} />;
  }

  if (isError) {
    return (
      <FormFunction
        text={"Gagal menyimpan data. Coba kembali beberapa saat. (" + msg + ")"}
        push={home}
        status={500}
      />
    );
  }

  const getPublikasi = async () => {
    setLoadingKatJadwal(true);
    const options = {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyKatJadwal,
      },
    };
    const response = await axios.get(apiKatJadwal + "/v1", options);
    setDataKatJadwal(response.data);
    setLoadingKatJadwal(false);
  };

  const getGereja = async () => {
    setLoadingGereja(true);
    const options = {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyGereja,
      },
    };
    const response = await axios.get(apiGereja + "/v1", options);
    setDataGereja(response.data);
    setLoadingGereja(false);
  };

  return (
    <>
      {/* <Hero title={pageTitle} subtitle={null} /> */}
      <FormContainer>
        <form onSubmit={Register} className="box">
          <p className="has-text-centered">{msg}</p>
          <AddInput
            label={"ID Jadwal"}
            value={idJadwal}
            change={(e) => setIdJadwal(e.target.value)}
            type={"text"}
            isRequired={true}
          />
          <div className="field mt-5">
            <label className="label">Pilih Kategori</label>
            <div className="control">
              <div className={`select ${loadingKatJadwal && "is-loading"}`}>
                {katJadwalState && katJadwalState.length > 0 ? (
                  <select
                    value={katJadwal}
                    onChange={(e) => setKategori(e.target.value)}
                  >
                    {katJadwalState.map((item) => (
                      <option key={item.id} value={item.nama}>
                        {item.nama}
                      </option>
                    ))}
                  </select>
                ) : (
                  <select disabled>
                    <option>Data kosong</option>
                  </select>
                )}
              </div>
            </div>
          </div>
          <div className="field mt-5">
            <label className="label">ID Gereja</label>
            <div className="control">
              <div className={`select ${loadingGereja && "is-loading"}`}>
                {gerejaState && gerejaState.length > 0 ? (
                  <select
                    value={idGereja}
                    onChange={(e) => setIdGereja(e.target.value)}
                  >
                    {gerejaState.map((item) => (
                      <option key={item.id} value={item.nm_grj}>
                        {item.nm_grj}
                      </option>
                    ))}
                  </select>
                ) : (
                  <select disabled>
                    <option>Data kosong</option>
                  </select>
                )}
              </div>
            </div>
          </div>
          {/* <AddInput
            label={"ID Gereja"}
            value={idGereja}
            change={(e) => setIdGereja(e.target.value)}
            type={"text"}
            isRequired={true}
          /> */}
          <AddInput
            label={"Nama Ibadah"}
            value={namaIbadah}
            change={(e) => setNamaIbadah(e.target.value)}
            type={"text"}
            isRequired={true}
          />
          <AddInput
            label={"Tanggal Ibadah"}
            value={tglIbadah}
            change={(e) => setTglIbadah(e.target.value)}
            type={"text"}
            isRequired={true}
          />
          <AddInput
            label={"Jam Ibadah"}
            value={jamIbadah}
            change={(e) => setJamIbadah(e.target.value)}
            type={"text"}
            isRequired={true}
          />
          <div className="field mt-5">
            <label className="label">Apakah Live?</label>
            <div className="control">
              <div className="select">
                <select value={live} onChange={(e) => setLive(e.target.value)}>
                  <option value="false">Tidak</option>
                  <option value="true">Ya</option>
                </select>
              </div>
            </div>
          </div>
          <div className="field mt-5">
            <FormAddButton type={"save"} loading={isLoading} goto={null} />
            <FormAddButton type={"back"} loading={null} goto={home} />
          </div>
        </form>
      </FormContainer>
    </>
  );
};

export default AddJadwal;
