import { createContext } from "react";

export const DataUser = createContext(null);
export const DataRayon = createContext(null);
export const DataGereja = createContext(null);
export const DataJemaat = createContext(null);
export const DataPendeta = createContext(null);
export const DataWilayah = createContext(null);
export const DataKatPublikasi = createContext(null);
export const DataKatJadwal = createContext(null);
export const DataKhotbah = createContext(null);
export const DataJadwal = createContext(null);
export const DataBerita = createContext(null);
