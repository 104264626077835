import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import { Button, Descriptions, Space } from "antd";
import { ArrowLeftOutlined, DeleteOutlined } from "@ant-design/icons";

import { apiJemaat, keyJemaat } from "../../libs/vars";
import { DataJemaat } from "../../libs/context";

import {
  FormContainer,
  ViewImage,
  ViewInput,
} from "../../components/Forms.layout";
import Hero from "../../components/Hero";
import FormFunction from "../../components/Forms.function";
import { FormDetailsButton } from "../../components/Forms.buttons";

const ViewJemaat = ({ title, home }) => {
  const history = useHistory();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isError, setIsError] = useState(false);
  const { pageTitle, jemaatState } = useContext(DataJemaat);

  const [str, setStr] = useState({
    id: "",
    nik: "",
    namadepan: "",
    namatengah: "",
    namabelakang: "",
    gereja: "",
    rayon: "",
    kelamin: "",
    hp: "",
    tanggal_lahir: "",
    jalan: "",
    kel: "",
    kec: "",
    kota: "",
    pekerjaan: "",
    status_nikah: "",
    status_pelayanan: "",
    surat_baptis: "",
    surat_nikah: "",
    foto: "",
    tanggal_verifikasi: "",
    verified: "",
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    // const data = JSON.parse(localStorage.getItem("dataJemaat"));
    // const filterData = data.filter((item) => item.id === id);
    const filterData = jemaatState.filter((item) => item.id === id);
    if (filterData) {
      setStr({
        id: filterData.map((item) => item.id),
        nik: filterData.map((item) => item.nik),
        namadepan: filterData.map((item) => item.nm_depan),
        namatengah: filterData.map((item) => item.nm_tengah),
        namabelakang: filterData.map((item) => item.nm_belakang),
        gereja: filterData.map((item) => item.id_grj),
        rayon: filterData.map((item) => item.id_rayon),
        kelamin: filterData.map((item) => item.jns_kel),
        hp: filterData.map((item) => item.nmr_hp),
        tanggal_lahir: filterData.map((item) => item.tgl_lhr),
        jalan: filterData.map((item) => item.almt_jln),
        kel: filterData.map((item) => item.almt_kel),
        kec: filterData.map((item) => item.almt_kec),
        kota: filterData.map((item) => item.almt_kotakab),
        pekerjaan: filterData.map((item) => item.pekerjaan),
        status_nikah: filterData.map((item) => item.st_nikah),
        status_pelayanan: filterData.map((item) => item.st_pelayanan),
        surat_baptis: filterData.map((item) => item.srt_baptis),
        surat_nikah: filterData.map((item) => item.srt_nikah),
        foto: filterData.map((item) => item.img_jemaat),
        tanggal_verifikasi: filterData.map((item) => item.tgl_verifikasi),
        verified: filterData.map((item) => item.verified),
      });
    }
  };

  const Delete = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const options = {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyJemaat,
      },
    };
    const konfirmasi = window.prompt(
      "Isi dengan " + str.id + " untuk menghapus data."
    );
    if (konfirmasi === str.id) {
      await axios
        .delete(apiJemaat + "/v1/" + id, options)
        .then(() => {
          localStorage.removeItem("singleJemaat");
          setIsLoading(false);
          setIsSubmit(true);
          setMsg("Data berhasil dihapus!");
        })
        .catch((error) => {
          setIsLoading(false);
          setMsg("Gagal menghapus data. Coba kembali beberapa saat.");
          console.log(error);
          setIsError(true);
        });
    } else {
      setIsLoading(false);
      setMsg("Isian salah! Gagal menghapus data, silahkan coba kembali.");
      setIsError(true);
    }
  };

  if (isSubmit) {
    return <FormFunction title="" text={msg} push={home} status={200} />;
  }

  if (isError) {
    return <FormFunction title="" text={msg} push={home} status={500} />;
  }

  return (
    <>
      {/* <Hero title={`${pageTitle}: ${str.id}`} subtitle={null} />
      <FormContainer>
        <form className="box">
          <ViewInput label={"ID Jemaat"} value={str.id} type={"text"} />
          <ViewInput label={"NIK"} value={str.nik} type={"text"} />
          <ViewInput label={"Nama Depan"} value={str.namadepan} type={"text"} />
          <ViewInput
            label={"Nama Tengah"}
            value={str.namatengah}
            type={"text"}
          />
          <ViewInput
            label={"Nama Belakang"}
            value={str.namabelakang}
            type={"text"}
          />
          <ViewInput label={"Kode Gereja"} value={str.gereja} type={"text"} />
          <ViewInput label={"Rayon"} value={str.rayon} type={"text"} />
          <ViewInput
            label={"Jenis Kelamin"}
            value={str.kelamin}
            type={"text"}
          />
          <ViewInput label={"Nomor HP"} value={str.hp} type={"text"} />
          <ViewInput
            label={"Tanggal Lahir"}
            value={str.tanggal_lahir}
            type={"text"}
          />
          <ViewInput label={"Alamat: Jalan"} value={str.jalan} type={"text"} />
          <ViewInput
            label={"Alamat: Kelurahan"}
            value={str.kel}
            type={"text"}
          />
          <ViewInput
            label={"Alamat: Kecamatan"}
            value={str.kec}
            type={"text"}
          />
          <ViewInput
            label={"Alamat: Kota/Kabupaten"}
            value={str.kota}
            type={"text"}
          />
          <ViewInput label={"Pekerjaan"} value={str.pekerjaan} type={"text"} />
          <ViewInput
            label={"Status Nikah"}
            value={str.status_nikah}
            type={"text"}
          />
          <ViewInput
            label={"Status Pelayanan"}
            value={str.status_pelayanan}
            type={"text"}
          />
          <ViewInput
            label={"Surat Baptis"}
            value={str.surat_baptis}
            type={"text"}
          />
          <ViewInput
            label={"Surat Nikah"}
            value={str.surat_nikah}
            type={"text"}
          />
          <ViewImage label={"Foto Jemaat"} src={str.foto} text={""} />
          <div className="field mt-5">
            <FormDetailsButton type={"back"} loading={null} goto={home} />
            <FormDetailsButton
              type={"delete"}
              loading={isLoading}
              goto={null}
              script={Delete}
            />
          </div>
        </form>
      </FormContainer> */}
      <Descriptions
        title={`${pageTitle}: ${str.id}`}
        bordered
        column={{
          xxl: 4,
          xl: 3,
          lg: 3,
          md: 3,
          sm: 2,
          xs: 1,
        }}
        extra={
          <Space wrap>
            <Button
              type="primary"
              icon={<ArrowLeftOutlined />}
              onClick={() => history.push(home)}
            >
              Kembali
            </Button>
            <Button
              type="default"
              loading={isLoading}
              disabled={isLoading}
              danger
              icon={<DeleteOutlined />}
              onClick={() => Delete}
            >
              Hapus
            </Button>
          </Space>
        }
      >
        <Descriptions.Item label="ID Jemaat">{str.id}</Descriptions.Item>
        <Descriptions.Item label="NIK">{str.nik}</Descriptions.Item>
        <Descriptions.Item label="Nama">
          {str.namadepan} {str.namatengah} {str.namabelakang}
        </Descriptions.Item>
        <Descriptions.Item label="Kode Gereja">{str.gereja}</Descriptions.Item>
        <Descriptions.Item label="Rayon">{str.rayon}</Descriptions.Item>
        <Descriptions.Item label="Jenis Kelamin">
          {str.kelamin}
        </Descriptions.Item>
        <Descriptions.Item label="Nomor HP">{str.hp}</Descriptions.Item>
        <Descriptions.Item label="Tanggal Lahir">
          {str.tanggal_lahir}
        </Descriptions.Item>
        <Descriptions.Item label="Alamat">
          {str.jalan} {str.kel} {str.kec}
        </Descriptions.Item>
        <Descriptions.Item label="Kab/Kota">{str.kota}</Descriptions.Item>
        <Descriptions.Item label="Pekerjaan">{str.pekerjaan}</Descriptions.Item>
        <Descriptions.Item label="Status Nikah">
          {str.st_nikah}
        </Descriptions.Item>
        <Descriptions.Item label="Status Pelayanan">
          {str.st_pelayanan}
        </Descriptions.Item>
        <Descriptions.Item label="Surat Baptis">
          {str.srt_baptis}
        </Descriptions.Item>
        <Descriptions.Item label="Surat Nikah">
          {str.srt_nikah}
        </Descriptions.Item>
        <Descriptions.Item label="Foto Jemaat">
          <img src={str.foto} alt="Foto" />
        </Descriptions.Item>
        <Descriptions.Item label="Verifikasi">{str.verified}</Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default ViewJemaat;
