import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import axios from "axios";
import { apiGereja, keyGereja } from "../libs/vars";

let gereja = (set, get) => ({
  _hasHydrated: false,
  auth: "",
  dataState: [],
  fetchGerejaStore: async () => {
    const response = await axios.get(apiGereja + "/v1", {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyGereja,
      },
    });
    set({ dataState: await response.data });
  },
  updateGerejaAuth: (key) => set({ auth: key }),
  updateGerejaStore: (data) => set({ dataState: data }),
  viewSingleGereja: (ids) => get().dataState.filter((item) => item.id === ids),
  clearGerejaStore: () => set({ dataState: [] }),
  setHasHydrated: (state) => {
    set({ _hasHydrated: state });
  },
});

gereja = persist(gereja, {
  name: "data-gereja",
  onRehydrateStorage: () => (state) => {
    state.setHasHydrated(true);
  },
});

export const GerejaStore = create(devtools(gereja));
