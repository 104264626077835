const Hero = ({ title, subtitle }) => {
  return (
    <section class="hero is-link">
      <div class="hero-body">
        <p class="title">{title}</p>
        {subtitle && <p class="subtitle">{subtitle}</p>}
      </div>
    </section>
  );
};

export default Hero;
