import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import axios from "axios";
import { apiRayon, keyRayon } from "../libs/vars";

let rayon = (set, get) => ({
  _hasHydrated: false,
  auth: "",
  dataState: [],
  fetchRayonStore: async () => {
    const response = await axios.get(apiRayon + "/v1", {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyRayon,
      },
    });
    set({ dataState: await response.data });
  },
  updateRayonAuth: (key) => set({ auth: key }),
  updateRayonStore: (data) => set({ dataState: data }),
  viewSingleRayon: (ids) => get().dataState.filter((item) => item.id === ids),
  clearRayonStore: () => set({ dataState: [] }),
  setHasHydrated: (state) => {
    set({ _hasHydrated: state });
  },
});

rayon = persist(rayon, { name: "data-rayon" });

export const RayonStore = create(devtools(rayon));
