import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { FooterSimple } from "./Footer";
import { apiUrl } from "../libs/vars";

const Register = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [msg, setMsg] = useState("");
  const history = useHistory();

  const Register = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios.post(apiUrl + "/users", {
        name: name,
        email: email,
        password: password,
        confPassword: confPassword,
      });
      setIsLoading(false);
      history.push("/");
    } catch (error) {
      if (error.response) {
        setIsLoading(false);
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <section className="hero has-background-grey-light is-fullheight is-fullwidth">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-4-desktop">
              <form onSubmit={Register} className="box">
                <p className="has-text-centered">{msg}</p>
                <div className="field mt-5">
                  <label className="label">Name</label>
                  <div className="controls">
                    <input
                      type="text"
                      className="input"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required="required"
                    />
                  </div>
                </div>
                <div className="field mt-5">
                  <label className="label">Email</label>
                  <div className="controls">
                    <input
                      type="text"
                      className="input"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required="required"
                    />
                  </div>
                </div>
                <div className="field mt-5">
                  <label className="label">Password</label>
                  <div className="controls">
                    <input
                      type="password"
                      className="input"
                      placeholder="******"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required="required"
                    />
                  </div>
                </div>
                <div className="field mt-5">
                  <label className="label">Confirm Password</label>
                  <div className="controls">
                    <input
                      type="password"
                      className="input"
                      placeholder="******"
                      value={confPassword}
                      onChange={(e) => setConfPassword(e.target.value)}
                      required="required"
                    />
                  </div>
                </div>
                <div className="field mt-5">
                  <button
                    className={`button is-dark is-fullwidth my-2 ${
                      isLoading ? "is-loading" : ""
                    }`}
                    disabled={isLoading}
                  >
                    Register
                  </button>
                  <button
                    onClick={() => history.goBack()}
                    className="button is-light is-fullwidth my-2"
                  >
                    Kembali
                  </button>
                </div>
              </form>
            </div>
          </div>
          <FooterSimple />
        </div>
      </div>
    </section>
  );
};

export default Register;
