import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { apiGereja, keyGereja } from "../libs/vars";
import FormFunction from "./Forms.function";
import { FormAddButton } from "./Forms.buttons";
import { AddInput, FormContainer } from "./Forms.layout";
import Hero from "./Hero";

const AddGereja = ({ pageTitle }) => {
  const home = "/gereja";
  // const pageTitle = "Gereja";
  const [isLoading, setIsLoading] = useState(false);
  const [idGereja, setIdGereja] = useState("");
  const [idPendeta, setIdPendeta] = useState("");
  const [namaGereja, setNamaGereja] = useState("");
  const [gpsLat, setGpsLat] = useState("");
  const [gpsLong, setGpsLong] = useState("");
  const [mapsLink, setMapsLink] = useState("");
  const [alamatJalan, setAlamatJalan] = useState("");
  const [alamatKel, setAlamatKel] = useState("");
  const [alamatKec, setAlamatKec] = useState("");
  const [alamatKota, setAlamatKota] = useState("");
  const [statusGereja, setStatusGereja] = useState("");
  const [jumlahIbadah, setJumlahIbadah] = useState("");
  const [imgDepan, setImgDepan] = useState("");
  const [imgSamping1, setImgSamping1] = useState("");
  const [imgSamping2, setImgSamping2] = useState("");
  const [imgBelakang, setImgBelakang] = useState("");
  const [imgDalam1, setImgDalam1] = useState("");
  const [imgDalam2, setImgDalam2] = useState("");
  const [msg, setMsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isError, setIsError] = useState(false);
  const history = useHistory();

  const Register = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const payload = {
      id: idGereja,
      id_pdt: idPendeta,
      nm_grj: namaGereja,
      gps_lat: gpsLat,
      gps_long: gpsLong,
      gmaps_link: mapsLink,
      almt_jln: alamatJalan,
      almt_kel: alamatKel,
      almt_kec: alamatKec,
      almt_kotakab: alamatKota,
      stts_grj: statusGereja,
      jum_ibdh: jumlahIbadah,
      img_dpn: imgDepan,
      img_samp1: imgSamping1,
      img_samp2: imgSamping2,
      img_blkg: imgBelakang,
      img_dlm_1: imgDalam1,
      img_dlm_2: imgDalam2,
    };
    const options = {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyGereja,
      },
    };
    // try {
    //   await axios.post(apiGereja + "/v1", payload, options);
    //   setIsLoading(false);
    //   history.push(home);
    // } catch (error) {
    //   if (error.response) {
    //     setIsLoading(false);
    //     setMsg(error.response.data.msg);
    //   }
    // }
    await axios
      .post(apiGereja + "/v1", payload, options)
      .then(() => {
        setIsLoading(false);
        setMsg("Data tersimpan!");
        setIsSubmit(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setMsg(error.message);
        setIsError(true);
      });
  };

  if (isSubmit) {
    return <FormFunction text={msg} push={home} status={200} />;
  }

  if (isError) {
    return (
      <FormFunction
        text={"Gagal menyimpan data. Coba kembali beberapa saat. (" + msg + ")"}
        push={home}
        status={500}
      />
    );
  }

  return (
    <>
      {/* <Hero title={pageTitle} subtitle={null} /> */}
      <FormContainer>
        <form onSubmit={Register} className="box">
          <p className="has-text-centered">{msg}</p>
          <AddInput
            label={"ID Gereja"}
            value={idGereja}
            change={(e) => setIdGereja(e.target.value)}
            type={"text"}
            isRequired={true}
          />
          <AddInput
            label={"ID Gembala"}
            value={idPendeta}
            change={(e) => setIdPendeta(e.target.value)}
            type={"text"}
            isRequired={true}
          />
          <AddInput
            label={"Nama Gereja"}
            value={namaGereja}
            change={(e) => setNamaGereja(e.target.value)}
            type={"text"}
            isRequired={true}
          />
          <AddInput
            label={"Koordinat (Lat)"}
            value={gpsLat}
            change={(e) => setGpsLat(e.target.value)}
            type={"text"}
            isRequired={false}
          />
          <AddInput
            label={"Koordinat (Long"}
            value={gpsLong}
            change={(e) => setGpsLong(e.target.value)}
            type={"text"}
            isRequired={false}
          />
          <AddInput
            label={"Link Google Maps"}
            value={mapsLink}
            change={(e) => setMapsLink(e.target.value)}
            type={"text"}
            isRequired={true}
          />
          <AddInput
            label={"Alamat: Jalan"}
            value={alamatJalan}
            change={(e) => setAlamatJalan(e.target.value)}
            type={"text"}
            isRequired={true}
          />
          <AddInput
            label={"Alamat: Kelurahan"}
            value={alamatKel}
            change={(e) => setAlamatKel(e.target.value)}
            type={"text"}
            isRequired={false}
          />
          <AddInput
            label={"Alamat: Kecamatan"}
            value={alamatKec}
            change={(e) => setAlamatKec(e.target.value)}
            type={"text"}
            isRequired={false}
          />
          <AddInput
            label={"Alamat: Kota/Kabupaten"}
            value={alamatKota}
            change={(e) => setAlamatKota(e.target.value)}
            type={"text"}
            isRequired={true}
          />
          <AddInput
            label={"Status Gereja"}
            value={statusGereja}
            change={(e) => setStatusGereja(e.target.value)}
            type={"text"}
            isRequired={false}
          />
          <AddInput
            label={"Jumlah Ibadah"}
            value={jumlahIbadah}
            change={(e) => setJumlahIbadah(e.target.value)}
            type={"text"}
            isRequired={false}
          />
          <div className="file has-name mt-5">
            <label className="file-label">
              <input
                className="file-input"
                type="file"
                onChange={(e) => setImgDepan(e.target.value)}
                value={imgDepan}
              />
              <span className="file-cta">
                <span className="file-icon">
                  <i className="fas fa-upload"></i>
                </span>
                <span className="file-label">Unggah Tampak Depan…</span>
              </span>
              <span className="file-name"></span>
            </label>
          </div>
          <div className="file has-name mt-5">
            <label className="file-label">
              <input
                className="file-input"
                type="file"
                onChange={(e) => setImgSamping1(e.target.value)}
                value={imgSamping1}
              />
              <span className="file-cta">
                <span className="file-icon">
                  <i className="fas fa-upload"></i>
                </span>
                <span className="file-label">Unggah Tampak Samping…</span>
              </span>
              <span className="file-name"></span>
            </label>
          </div>
          <div className="file has-name mt-5">
            <label className="file-label">
              <input
                className="file-input"
                type="file"
                onChange={(e) => setImgSamping2(e.target.value)}
                value={imgSamping2}
              />
              <span className="file-cta">
                <span className="file-icon">
                  <i className="fas fa-upload"></i>
                </span>
                <span className="file-label">Unggah Tampak Samping…</span>
              </span>
              <span className="file-name"></span>
            </label>
          </div>
          <div className="file has-name mt-5">
            <label className="file-label">
              <input
                className="file-input"
                type="file"
                onChange={(e) => setImgBelakang(e.target.value)}
                value={imgBelakang}
              />
              <span className="file-cta">
                <span className="file-icon">
                  <i className="fas fa-upload"></i>
                </span>
                <span className="file-label">Unggah Tampak Belakang…</span>
              </span>
              <span className="file-name"></span>
            </label>
          </div>
          <div className="file has-name mt-5">
            <label className="file-label">
              <input
                className="file-input"
                type="file"
                onChange={(e) => setImgDalam1(e.target.value)}
                value={imgDalam1}
              />
              <span className="file-cta">
                <span className="file-icon">
                  <i className="fas fa-upload"></i>
                </span>
                <span className="file-label">Unggah Tampak Dalam…</span>
              </span>
              <span className="file-name"></span>
            </label>
          </div>
          <div className="file has-name mt-5">
            <label className="file-label">
              <input
                className="file-input"
                type="file"
                onChange={(e) => setImgDalam2(e.target.value)}
                value={imgDalam2}
              />
              <span className="file-cta">
                <span className="file-icon">
                  <i className="fas fa-upload"></i>
                </span>
                <span className="file-label">Unggah Tampak Dalam…</span>
              </span>
              <span className="file-name"></span>
            </label>
          </div>
          <div className="field mt-5">
            <FormAddButton type={"save"} loading={isLoading} goto={null} />
            <FormAddButton type={"back"} loading={null} goto={home} />
          </div>
        </form>
      </FormContainer>
    </>
  );
};

export default AddGereja;
