import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { apiGereja, apiJemaat, keyGereja, keyJemaat } from "../libs/vars";
import { AddInput, FormContainer } from "./Forms.layout";
import { FormAddButton } from "./Forms.buttons";
import Hero from "./Hero";
import FormFunction from "./Forms.function";

const AddJemaat = ({ pageTitle }) => {
  const home = "/jemaat";
  // const pageTitle = "Jemaat";
  const [isLoading, setIsLoading] = useState(false);
  const [loadingRayon, setLoadingRayon] = useState(false);
  const [idJemaat, setIdJemaat] = useState("");
  const [nikJemaat, setNikJemaat] = useState("");
  const [namaDepan, setNamaDepan] = useState("");
  const [namaTengah, setNamaTengah] = useState("");
  const [namaBelakang, setNamaBelakang] = useState("");
  const [idGereja, setIdGereja] = useState("");
  const [idRayon, setIdRayon] = useState("");
  const [jenisKelamin, setJenisKelamin] = useState("");
  const [nomorHp, setNomorHp] = useState("");
  const [tglLahir, setTglLahir] = useState("");
  const [alamatJalan, setAlamatJalan] = useState("");
  const [alamatKel, setAlamatKel] = useState("");
  const [alamatKec, setAlamatKec] = useState("");
  const [alamatKota, setAlamatKota] = useState("");
  const [pekerjaan, setPekerjaan] = useState("");
  const [statusNikah, setStatusNikah] = useState("");
  const [statusPelayanan, setStatusPelayanan] = useState("");
  const [suratBaptis, setSuratBaptis] = useState("");
  const [suratNikah, setSuratNikah] = useState("");
  const [imgJemaat, setImgJemaat] = useState("");
  const [tglVerifikasi, setTglVerifikasi] = useState("");
  const [verified, setVerified] = useState("");
  const [rayon, setRayon] = useState([]);
  const [dataGereja, setDataGereja] = useState([]);
  const [loadingGereja, setLoadingGereja] = useState(false);
  const [msg, setMsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isError, setIsError] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getData();
    getGereja();
  }, []);

  const Register = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const payload = {
      id: idJemaat,
      nik: nikJemaat,
      nm_depan: namaDepan,
      nm_tengah: namaTengah,
      nm_belakang: namaBelakang,
      id_grj: idGereja,
      id_rayon: idRayon,
      jns_kel: jenisKelamin,
      nmr_hp: nomorHp,
      tgl_lhr: tglLahir,
      almt_jln: alamatJalan,
      almt_kel: alamatKel,
      almt_kec: alamatKec,
      almt_kotakab: alamatKota,
      pekerjaan: pekerjaan,
      st_nikah: statusNikah,
      st_pelayanan: statusPelayanan,
      srt_baptis: suratBaptis,
      srt_nikah: suratNikah,
      img_jemaat: imgJemaat,
      tgl_verifikasi: tglVerifikasi,
      verified: verified,
    };
    const options = {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyJemaat,
      },
    };
    await axios
      .post(apiJemaat + "/v1", payload, options)
      .then(() => {
        setIsLoading(false);
        setMsg("Data tersimpan!");
        setIsSubmit(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setMsg(error.message);
        setIsError(true);
      });
  };

  // const getRayon = async () => {
  //   setLoadingRayon(true);
  //   const options = {
  //     withCredentials: false,
  //     headers: {
  //       "Content-Type": "application/json",
  //       "X-API-Key": keyRayon,
  //     },
  //   };
  //   const response = await axios.get(apiRayon + "/v1", options);
  //   setRayon(response.data);
  //   setLoadingRayon(false);
  // };

  if (isSubmit) {
    return <FormFunction text={msg} push={home} status={200} />;
  }

  if (isError) {
    return (
      <FormFunction
        text={"Gagal menyimpan data. Coba kembali beberapa saat. (" + msg + ")"}
        push={home}
        status={500}
      />
    );
  }

  const getData = () => {
    setLoadingRayon(true);
    const response = JSON.parse(localStorage.getItem("dataRayon"));
    if (response) {
      setRayon(response);
      setLoadingRayon(false);
    }
  };

  const getGereja = async () => {
    setLoadingGereja(true);
    const options = {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyGereja,
      },
    };
    const response = await axios.get(apiGereja + "/v1", options);
    setDataGereja(response.data);
    setLoadingGereja(false);
  };

  const opsiJenisKelamin = [
    {
      name: "Pria",
    },
    {
      name: "Wanita",
    },
  ];

  const opsiStatusNikah = [
    {
      name: "Lajang",
    },
    {
      name: "Menikah",
    },
    {
      name: "Cerai",
    },
  ];

  return (
    <>
      {/* <Hero title={pageTitle} subtitle={null} /> */}
      <FormContainer>
        <form onSubmit={Register} className="box">
          <p className="has-text-centered">{msg}</p>
          <AddInput
            label={"ID Jemaat"}
            value={idJemaat}
            change={(e) => setIdJemaat(e.target.value)}
            type={"text"}
          />
          <AddInput
            label={"NIK"}
            value={nikJemaat}
            change={(e) => setNikJemaat(e.target.value)}
            type={"text"}
          />
          <AddInput
            label={"Nama Depan"}
            value={namaDepan}
            change={(e) => setNamaDepan(e.target.value)}
            type={"text"}
          />
          <AddInput
            label={"Nama Tengah"}
            value={namaTengah}
            change={(e) => setNamaTengah(e.target.value)}
            type={"text"}
          />
          <AddInput
            label={"Nama Belakang"}
            value={namaBelakang}
            change={(e) => setNamaBelakang(e.target.value)}
            type={"text"}
          />
          <div className="field mt-5">
            <label className="label">ID Gereja</label>
            <div className="control">
              <div className={`select ${loadingGereja && "is-loading"}`}>
                {dataGereja && dataGereja.length > 0 ? (
                  <select
                    value={idGereja}
                    onChange={(e) => setIdGereja(e.target.value)}
                  >
                    {dataGereja.map((item) => (
                      <option key={item.id} value={item.nm_grj}>
                        {item.nm_grj}
                      </option>
                    ))}
                  </select>
                ) : (
                  <select disabled>
                    <option>Data kosong</option>
                  </select>
                )}
              </div>
            </div>
          </div>
          {/* <AddInput
            label={"Kode Gereja"}
            value={idGereja}
            change={(e) => setIdGereja(e.target.value)}
            type={"text"}
          /> */}
          <div className="field mt-5">
            <label className="label">Pilih Rayon</label>
            <div className="control">
              <div className={`select ${loadingRayon && "is-loading"}`}>
                {rayon && rayon.length > 0 ? (
                  <select
                    value={idRayon}
                    onChange={(e) => setIdRayon(e.target.value)}
                  >
                    {rayon.map((item) => (
                      <option key={item.id} value={item.nm_rayon}>
                        {item.nm_rayon}
                      </option>
                    ))}
                  </select>
                ) : (
                  <select disabled>
                    <option>Data kosong</option>
                  </select>
                )}
              </div>
            </div>
          </div>
          <div className="field mt-5">
            <label className="label">Jenis Kelamin</label>
            <div className="control">
              <div className="select">
                <select
                  value={jenisKelamin}
                  onChange={(e) => setJenisKelamin(e.target.value)}
                >
                  {opsiJenisKelamin.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <AddInput
            label={"Nomor HP"}
            value={nomorHp}
            change={(e) => setNomorHp(e.target.value)}
            type={"text"}
          />
          <AddInput
            label={"Tanggal Lahir"}
            value={tglLahir}
            change={(e) => setTglLahir(e.target.value)}
            type={"text"}
          />
          <AddInput
            label={"Alamat: Jalan"}
            value={alamatJalan}
            change={(e) => setAlamatJalan(e.target.value)}
            type={"text"}
          />
          <AddInput
            label={"Alamat: Kelurahan"}
            value={alamatKel}
            change={(e) => setAlamatKel(e.target.value)}
            type={"text"}
          />
          <AddInput
            label={"Alamat: Kecamatan"}
            value={alamatKec}
            change={(e) => setAlamatKec(e.target.value)}
            type={"text"}
          />
          <AddInput
            label={"Alamat: Kota/Kabupaten"}
            value={alamatKota}
            change={(e) => setAlamatKota(e.target.value)}
            type={"text"}
          />
          <AddInput
            label={"Pekerjaan"}
            value={pekerjaan}
            change={(e) => setPekerjaan(e.target.value)}
            type={"text"}
          />
          <div className="field mt-5">
            <label className="label">Status Nikah</label>
            <div className="control">
              <div className="select">
                <select
                  value={statusNikah}
                  onChange={(e) => setStatusNikah(e.target.value)}
                >
                  {opsiStatusNikah.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <AddInput
            label={"Status Pelayanan"}
            value={statusPelayanan}
            change={(e) => setStatusPelayanan(e.target.value)}
            type={"text"}
          />
          <AddInput
            label={"Surat Baptis"}
            value={suratBaptis}
            change={(e) => setSuratBaptis(e.target.value)}
            type={"text"}
          />
          <AddInput
            label={"Surat Nikah"}
            value={suratNikah}
            change={(e) => setSuratNikah(e.target.value)}
            type={"text"}
          />
          <div className="file has-name mt-5">
            <label className="file-label">
              <input
                className="file-input"
                type="file"
                onChange={(e) => setImgJemaat(e.target.value)}
                value={imgJemaat}
              />
              <span className="file-cta">
                <span className="file-icon">
                  <i className="fas fa-upload"></i>
                </span>
                <span className="file-label">Unggah foto diri…</span>
              </span>
              <span className="file-name"></span>
            </label>
          </div>
          <AddInput
            label={"Tanggal Verifikasi"}
            value={tglVerifikasi}
            change={(e) => setTglVerifikasi(e.target.value)}
            type={"text"}
          />
          <div className="field mt-5">
            <label className="label">Sudah Terverifikasi?</label>
            <div className="control">
              <div className="select">
                <select
                  value={verified}
                  onChange={(e) => setVerified(e.target.value)}
                >
                  <option value="false">Belum</option>
                  <option value="true">Sudah</option>
                </select>
              </div>
            </div>
          </div>
          <div className="field mt-5">
            <FormAddButton type={"save"} loading={isLoading} goto={null} />
            <FormAddButton type={"back"} loading={null} goto={home} />
          </div>
        </form>
      </FormContainer>
    </>
  );
};

export default AddJemaat;
