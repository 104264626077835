import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import axios from "axios";
import { apiWilayah, keyWilayah } from "../libs/vars";

let wilayah = (set, get) => ({
  _hasHydrated: false,
  auth: "",
  dataState: [],
  fetchWilayahStore: async () => {
    const response = await axios.get(apiWilayah + "/v1", {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyWilayah,
      },
    });
    set({ dataState: await response.data });
  },
  updateWilayahAuth: (key) => set({ auth: key }),
  updateWilayahStore: (data) => set({ dataState: data }),
  viewSingleWilayah: (ids) => get().dataState.filter((item) => item.id === ids),
  clearWilayahStore: () => set({ dataState: [] }),
  setHasHydrated: (state) => {
    set({ _hasHydrated: state });
  },
});

wilayah = persist(wilayah, { name: "data-wilayah" });

export const WilayahStore = create(devtools(wilayah));
