import { Switch, Route, useRouteMatch } from "react-router-dom";
import AddKatPublikasi from "./KatPublikasi.add";
import AddKatJadwal from "./KatJadwal.add";

export default function Kategori() {
  const { path } = useRouteMatch();
  const links = [
    {
      name: "publikasi",
      component: AddKatPublikasi,
    },
    {
      name: "jadwal",
      component: AddKatJadwal,
    },
  ];

  return (
    <Switch>
      {links.map((link, index) => (
        <Route key={index} path={`${path}/${link.name}`}>
          <link.component pageTitle={link.name} />
        </Route>
      ))}
    </Switch>
  );
}
