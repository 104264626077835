import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import { Button, Descriptions, Space } from "antd";
import { ArrowLeftOutlined, DeleteOutlined } from "@ant-design/icons";

import { apiKatPubs, keyKatPubs } from "../../libs/vars";
import { DataKatPublikasi } from "../../libs/context";
import { FormContainer, ViewInput } from "../../components/Forms.layout";
import Hero from "../../components/Hero";
import FormFunction from "../../components/Forms.function";
import { FormDetailsButton } from "../../components/Forms.buttons";

const ViewKatPublikasi = ({ title, home }) => {
  const history = useHistory();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isError, setIsError] = useState(false);
  const { pageTitle, katpublikasiState } = useContext(DataKatPublikasi);

  const [str, setStr] = useState({
    id: "",
    nama: "",
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    // const data = JSON.parse(localStorage.getItem("dataKatPubs"));
    // const filterData = data.filter((item) => item.id === id);
    const filterData = katpublikasiState.filter((item) => item.id === id);
    if (filterData) {
      setStr({
        id: filterData.map((item) => item.id),
        nama: filterData.map((item) => item.nama),
      });
    }
  };

  const Delete = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const options = {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyKatPubs,
      },
    };
    const konfirmasi = window.prompt(
      "Isi dengan " + str.id + " untuk menghapus data."
    );
    if (konfirmasi === str.id) {
      await axios
        .delete(apiKatPubs + "/v1/" + id, options)
        .then(() => {
          localStorage.removeItem("singleKatPublikasi");
          setIsLoading(false);
          setIsSubmit(true);
          setMsg("Data berhasil dihapus!");
        })
        .catch((error) => {
          setIsLoading(false);
          setMsg("Gagal menghapus data. Coba kembali beberapa saat.");
          console.log(error);
          setIsError(true);
        });
    } else {
      setIsLoading(false);
      setMsg("Isian salah! Gagal menghapus data, silahkan coba kembali.");
      setIsError(true);
    }
  };

  if (isSubmit) {
    return <FormFunction title="" text={msg} push={home} status={200} />;
  }

  if (isError) {
    return <FormFunction title="" text={msg} push={home} status={500} />;
  }

  return (
    <>
      {/* <Hero title={title} subtitle={null} />
      <FormContainer>
        <form className="box">
          <ViewInput label={"ID Kategori"} value={str.id} type={"text"} />
          <ViewInput
            label={"Nama Kategori Publikasi"}
            value={str.nama}
            type={"text"}
          />
          <div className="field mt-5">
            <FormDetailsButton type={"back"} loading={null} goto={home} />
            <FormDetailsButton
              type={"delete"}
              loading={isLoading}
              goto={null}
              script={Delete}
            />
          </div>
        </form>
      </FormContainer> */}
      <Descriptions
        title={`${pageTitle}: ${str.nama}`}
        bordered
        column={{
          xxl: 4,
          xl: 3,
          lg: 3,
          md: 3,
          sm: 2,
          xs: 1,
        }}
        extra={
          <Space wrap>
            <Button
              type="primary"
              icon={<ArrowLeftOutlined />}
              onClick={() => history.push(home)}
            >
              Kembali
            </Button>
            <Button
              type="default"
              loading={isLoading}
              disabled={isLoading}
              danger
              icon={<DeleteOutlined />}
              onClick={() => Delete}
            >
              Hapus
            </Button>
          </Space>
        }
      >
        <Descriptions.Item label="ID Kategori">{str.id}</Descriptions.Item>
        <Descriptions.Item label="Nama Kategori">{str.nama}</Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default ViewKatPublikasi;
