import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { apiWilayah, keyWilayah } from "../../libs/vars";
import FormFunction from "../../components/Forms.function";
import { FormAddButton } from "../../components/Forms.buttons";
import { AddInput, FormContainer } from "../../components/Forms.layout";
import Hero from "../../components/Hero";

const AddWilayah = ({ pageTitle }) => {
  const home = "/wilayah";
  // const pageTitle = "Wilayah";
  const [isLoading, setIsLoading] = useState(false);
  const [idWilayah, setIdWilayah] = useState("");
  const [nomorWilayah, setNoWilayah] = useState("");
  const [namaWilayah, setNamaWilayah] = useState("");
  const [msg, setMsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isError, setIsError] = useState(false);
  const history = useHistory();

  const Register = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const payload = {
      id: idWilayah,
      nmr_wil: nomorWilayah,
      nm_wil: namaWilayah,
    };
    const options = {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyWilayah,
      },
    };
    // try {
    //   await axios.post(apiWilayah + "/v1", payload, options);
    //   setIsLoading(false);
    //   history.push(home);
    // } catch (error) {
    //   if (error.response) {
    //     setIsLoading(false);
    //     setMsg(error.response.data.msg);
    //   }
    // }
    await axios
      .post(apiWilayah + "/v1", payload, options)
      .then(() => {
        setIsLoading(false);
        setMsg("Data tersimpan!");
        setIsSubmit(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setMsg(error.message);
        setIsError(true);
      });
  };

  if (isSubmit) {
    return <FormFunction title="" text={msg} push={home} status={200} />;
  }

  if (isError) {
    return (
      <FormFunction
        title=""
        text={"Gagal menyimpan data. Coba kembali beberapa saat. (" + msg + ")"}
        push={home}
        status={500}
      />
    );
  }

  return (
    <>
      {/* <Hero title={pageTitle} subtitle={null} /> */}
      <FormContainer>
        <form onSubmit={Register} className="box">
          <p className="has-text-centered">{msg}</p>
          <AddInput
            label={"ID Wilayah"}
            value={idWilayah}
            change={(e) => setIdWilayah(e.target.value)}
            type={"text"}
            isRequired={true}
          />
          <AddInput
            label={"Nomor Wilayah"}
            value={nomorWilayah}
            change={(e) => setNoWilayah(e.target.value)}
            type={"text"}
            isRequired={false}
          />
          <AddInput
            label={"Nama Wilayah"}
            value={namaWilayah}
            change={(e) => setNamaWilayah(e.target.value)}
            type={"text"}
            isRequired={true}
          />
          <div className="field mt-5">
            <FormAddButton type={"save"} loading={isLoading} goto={null} />
            <FormAddButton type={"back"} loading={null} goto={home} />
          </div>
        </form>
      </FormContainer>
    </>
  );
};

export default AddWilayah;
