import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import axios from "axios";
import { apiPendeta, keyPendeta } from "../libs/vars";

let pendeta = (set, get) => ({
  _hasHydrated: false,
  auth: "",
  dataState: [],
  fetchPendetaStore: async () => {
    const response = await axios.get(apiPendeta + "/v1", {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyPendeta,
      },
    });
    set({ dataState: await response.data });
  },
  updatePendetaAuth: (key) => set({ auth: key }),
  updatePendetaStore: (data) => set({ dataState: data }),
  viewSinglePendeta: (ids) => get().dataState.filter((item) => item.id === ids),
  clearPendetaStore: () => set({ dataState: [] }),
  setHasHydrated: (state) => {
    set({ _hasHydrated: state });
  },
});

pendeta = persist(pendeta, { name: "data-pendeta" });

export const PendetaStore = create(devtools(pendeta));
