/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useMemo,
} from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
// import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import { Column, HeaderCell, Cell } from "rsuite-table";
import { Layout, Space, Table, Button, Typography, theme } from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { apiNews, apiUrl, keyNews } from "../libs/vars";
import { DataBerita } from "../libs/context";
import { BeritaStore } from "../state/Berita";
import Topbar from "../components/Topbar";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import { Footer } from "../components/Footer";
import EditBerita from "./components/Berita.edit";
import ViewBerita from "../components/Hero";
import { IndexContainer } from "../components/Index.layout";
import "rsuite-table/dist/css/rsuite-table.css";

const { Content } = Layout;
const { Title } = Typography;

const BeritaStateSelector = (state) => state.dataState;

const Berita = ({ home }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const pageTitle = "News";
  const { path } = useRouteMatch();
  const [msg, setMessage] = useState("");
  // const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const [berita, setBerita] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const beritaState = BeritaStore(BeritaStateSelector);
  const { updateBeritaStore } = BeritaStore();

  useEffect(() => {
    refreshToken();
    // getBerita();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(apiUrl + "/auth/token");
      setToken(response.data.accessToken);
      const decoded = jwt_decode(response.data.accessToken);
      // setName(decoded.name);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        history.push("/");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(apiUrl + "/auth/token");
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        // setName(decoded.name);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getBerita = async () => {
    setIsLoading(true);
    try {
      const options = {
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "X-API-Key": keyNews,
        },
      };
      const response = await axiosJWT.get(apiNews + "/v1", options);
      // setBerita(response.data);
      // localStorage.setItem("dataBerita", JSON.stringify(response.data));
      updateBeritaStore(response.data);
      setIsLoading(false);
    } catch (error) {
      if (error.response) {
        setIsLoading(false);
        setMessage("Gagal memuat data!");
      }
    }
  };

  const contextValue = useMemo(
    () => ({ beritaState, getBerita, setIsLoading, isLoading, pageTitle }),
    [beritaState, getBerita]
  );

  return (
    <>
      {/* <Navbar /> */}
      <Sidebar />
      <Layout>
        <Topbar />
        <Content style={{ margin: "24px 16px 0" }}>
          <Title level={1} style={{ margin: "16px 0" }}>
            {pageTitle}
          </Title>
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
            }}
          >
            <Switch>
              <DataBerita.Provider value={contextValue}>
                <Route exact path={`${path}`}>
                  <Home msg={msg} />
                </Route>
                {/* <Route path={`${path}/edit/:id`}>
          <EditRayon home={home} />
        </Route> */}
                <Route path={`${path}/details/:id`}>
                  <ViewBerita title={pageTitle} home={home} />
                </Route>
              </DataBerita.Provider>
            </Switch>
          </div>
        </Content>
        {/* <Footer style={{ textAlign: "center" }}>MYGPDI Backend ©2023</Footer> */}
        {/* <Footer /> */}
      </Layout>
    </>
  );
};

const Home = () => {
  const history = useHistory();
  const { pageTitle, isLoading, getBerita } = useContext(DataBerita);

  function goTo() {
    history.push("/forms/berita");
  }

  return (
    <>
      {/* <Hero title={pageTitle} subtitle={null} /> */}
      <IndexContainer add={goTo} loading={isLoading} refresh={getBerita}>
        <div className="table-container">
          {/* <Tabel /> */}
          <TabelAlt />
        </div>
      </IndexContainer>
    </>
  );
};

const Tabel = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { getBerita, beritaState, isLoading } = useContext(DataBerita);

  async function Delete(key) {
    setLoading(true);
    const options = {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyNews,
      },
    };
    const konfirmasi = window.confirm("Yakin mau menghapus ID: " + key + "?");
    if (konfirmasi) {
      await axios
        .delete(apiNews + "/v1/" + key, options)
        .then((response) => {
          alert("Data berhasil dihapus.");
          setLoading(false);
          getBerita();
          setMessage(response);
        })
        .catch((error) => {
          setLoading(false);
          setMessage(error);
        });
    } else {
      setLoading(false);
    }
  }

  return (
    <Table
      data={beritaState}
      loading={isLoading}
      autoHeight
      cellBordered
      wordWrap="break-word"
    >
      <Column width={50} fixed resizable>
        <HeaderCell>No</HeaderCell>
        <Cell>
          {(rows, index) => {
            return index + 1;
          }}
        </Cell>
      </Column>
      {/* <Column width={100} sortable fixed resizable>
        <HeaderCell>ID</HeaderCell>
        <Cell dataKey="id" />
      </Column> */}
      <Column width={100} sortable fixed flexGrow={1}>
        <HeaderCell>Tanggal</HeaderCell>
        <Cell dataKey="tgl_berita" />
      </Column>
      <Column fixed flexGrow={2}>
        <HeaderCell>Judul</HeaderCell>
        <Cell>
          {(rowData) => {
            if (rowData.featured) {
              return `⭐ ${rowData.judul_berita}`;
            } else {
              return rowData.judul_berita;
            }
          }}
        </Cell>
      </Column>
      <Column width={200} sortable fixed flexGrow={3}>
        <HeaderCell>Kategori</HeaderCell>
        <Cell dataKey="kategori_berita" />
      </Column>
      <Column width={200} fixed flexGrow={4}>
        <HeaderCell>Gereja</HeaderCell>
        <Cell dataKey="id_grj" />
      </Column>
      <Column width={250} fixed>
        <HeaderCell>Tindakan</HeaderCell>
        <Cell>
          {(rows) => {
            return (
              <Space wrap>
                <Button
                  type="primary"
                  size="small"
                  disabled={loading}
                  icon={<EyeOutlined />}
                  onClick={() => history.push(`${path}/details/${rows.id}`)}
                >
                  Detail
                </Button>
                <Button
                  size="small"
                  disabled={loading}
                  icon={<EditOutlined />}
                  onClick={() => history.push(`${path}/edit/${rows.id}`)}
                >
                  Edit
                </Button>
                <Button
                  type="dashed"
                  size="small"
                  loading={loading}
                  disabled={loading}
                  icon={<DeleteOutlined />}
                  danger
                  onClick={() => Delete(rows.id)}
                >
                  Hapus
                </Button>
              </Space>
            );
          }}
        </Cell>
      </Column>
    </Table>
  );
};

function TabelAlt() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { getBerita, beritaState, isLoading } = useContext(DataBerita);

  async function Delete(key) {
    setLoading(true);
    const options = {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyNews,
      },
    };
    const konfirmasi = window.confirm("Yakin mau menghapus ID: " + key + "?");
    if (konfirmasi) {
      await axios
        .delete(apiNews + "/v1/" + key, options)
        .then((response) => {
          alert("Data berhasil dihapus.");
          setLoading(false);
          getBerita();
          setMessage(response);
        })
        .catch((error) => {
          setLoading(false);
          setMessage(error);
        });
    } else {
      setLoading(false);
    }
  }

  const kolom = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (rows) => <Link to={`${path}/details/${rows}`}>{rows}</Link>,
    },
    {
      title: "Tanggal",
      dataIndex: "tgl_berita",
      key: "tanggal",
      responsive: ["md"],
    },
    {
      title: "F",
      dataIndex: "featured",
      key: "featured",
      responsive: ["md"],
      render: (text) => (text ? "⭐" : ""),
    },
    {
      title: "Judul",
      dataIndex: "judul_berita",
      key: "judul",
      responsive: ["md"],
      render: (rowData) => {
        if (rowData.featured) {
          return `⭐ ${rowData.judul_berita}`;
        } else {
          return rowData.judul_berita;
        }
      },
    },
    {
      title: "Kategori",
      dataIndex: "kategori_berita",
      key: "kategori",
      responsive: ["md"],
    },
    {
      title: "Gereja",
      dataIndex: "id_grj",
      key: "gereja",
      responsive: ["md"],
    },
    {
      title: "Tindakan",
      key: "action",
      render: (_, rows) => (
        <Space size="middle" wrap>
          {/* <Button
            type="primary"
            size="small"
            disabled={loading}
            icon={<EyeOutlined />}
            onClick={() => history.push(`${path}/details/${rows.id}`)}
          >
            Detail
          </Button> */}
          <Button
            size="small"
            disabled={loading}
            icon={<EditOutlined />}
            onClick={() => history.push(`${path}/edit/${rows.id}`)}
          >
            Edit
          </Button>
          <Button
            type="dashed"
            size="small"
            loading={loading}
            disabled={loading}
            icon={<DeleteOutlined />}
            danger
            onClick={() => Delete(rows.id)}
          >
            Hapus
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Table
      dataSource={beritaState}
      loading={isLoading}
      columns={kolom}
      pagination={{ pageSize: 25 }}
    ></Table>
  );
}

export default Berita;
