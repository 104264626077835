import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import axios from "axios";
import { apiJemaat, keyJemaat } from "../libs/vars";

let jemaat = (set, get) => ({
  _hasHydrated: false,
  auth: "",
  dataState: [],
  fetchJemaatStore: async () => {
    const response = await axios.get(apiJemaat + "/v1", {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyJemaat,
      },
    });
    set({ dataState: await response.data });
  },
  updateJemaatAuth: (key) => set({ auth: key }),
  updateJemaatStore: (data) => set({ dataState: data }),
  viewSingleJemaat: (ids) => get().dataState.filter((item) => item.id === ids),
  clearJemaatStore: () => set({ dataState: [] }),
  setHasHydrated: (state) => {
    set({ _hasHydrated: state });
  },
});

jemaat = persist(jemaat, { name: "data-jemaat" });

export const JemaatStore = create(devtools(jemaat));
