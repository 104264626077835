import { Button, Result } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { FormContainer } from "./Forms.layout";

export default function FormFunction({ push, title, text, status }) {
  const history = useHistory();

  switch (status) {
    case 200:
      return (
        // <FormContainer>
        //   <div className="box">
        //     <h1 className="has-text-centered mb-4">{text}</h1>
        //     <button
        //       onClick={() => history.push(push)}
        //       className="button is-success is-fullwidth my-2"
        //     >
        //       Lanjutkan
        //     </button>
        //   </div>
        // </FormContainer>
        <Result
          status="success"
          title={title}
          subTitle={text}
          extra={[
            <Button
              type="primary"
              key="lanjut"
              onClick={() => history.push(push)}
              block
            >
              Lanjut
            </Button>,
          ]}
        />
      );

    case 500:
      return (
        // <FormContainer>
        //   <div className="box">
        //     <h1 className="has-text-centered mb-4">{text}</h1>
        //     <button
        //       onClick={() => history.push(push)}
        //       className="button is-light is-fullwidth my-2"
        //     >
        //       Kembali
        //     </button>
        //   </div>
        // </FormContainer>
        <Result
          status="error"
          title={title}
          subTitle={text}
          extra={[
            <Button
              type="primary"
              key="lanjut"
              onClick={() => history.push(push)}
              block
            >
              Kembali
            </Button>,
          ]}
        />
      );

    default:
      throw Error("Kode status " + status + " tidak ditemukan!");
  }
}
