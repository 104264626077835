import React from "react";
import { useHistory } from "react-router-dom";

export const FormAddButton = ({ type, loading, goto, script }) => {
  const history = useHistory();

  switch (type) {
    case "save":
      return (
        <button
          className={`button is-info is-fullwidth my-2 ${
            loading ? "is-loading" : ""
          }`}
          disabled={loading}
        >
          Simpan
        </button>
      );

    case "back":
      return (
        <button
          onClick={() => history.push(goto)}
          className="button is-light is-fullwidth my-2"
        >
          Kembali
        </button>
      );

    default:
      throw Error("Input type = " + type + " tidak ditemukan.");
  }
};

export const FormDetailsButton = ({ type, loading, goto, script }) => {
  const history = useHistory();

  switch (type) {
    case "back":
      return (
        <button
          onClick={() => history.push(goto)}
          className="button is-dark is-fullwidth my-2"
        >
          Kembali
        </button>
      );

    case "delete":
      return (
        <button
          className={`button is-outlined is-danger is-fullwidth my-2 ${
            loading ? "is-loading" : ""
          }`}
          onClick={script}
          disabled={loading}
        >
          Hapus
        </button>
      );

    default:
      throw Error("Input type = " + type + " tidak ditemukan.");
  }
};

export const FormEditButton = ({ type, loading, goto }) => {
  const history = useHistory();

  switch (type) {
    case "save":
      return (
        <button
          className={`button is-info is-fullwidth my-2 ${
            loading ? "is-loading" : ""
          }`}
          disabled={loading}
        >
          Simpan
        </button>
      );

    case "back":
      return (
        <button
          onClick={() => history.push(goto)}
          className="button is-light is-fullwidth my-2"
        >
          Kembali
        </button>
      );

    default:
      throw Error("Input type = " + type + " tidak ditemukan.");
  }
};
