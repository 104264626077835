import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import axios from "axios";
import { apiNews, keyNews } from "../libs/vars";

let berita = (set, get) => ({
  _hasHydrated: false,
  auth: "",
  dataState: [],
  fetchBeritaStore: async () => {
    const response = await axios.get(apiNews + "/v1", {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyNews,
      },
    });
    set({ dataState: await response.data });
  },
  updateBeritaAuth: (key) => set({ auth: key }),
  updateBeritaStore: (data) => set({ dataState: data }),
  viewSingleBerita: (ids) => get().dataState.filter((item) => item.id === ids),
  clearBeritaStore: () => set({ dataState: [] }),
  setHasHydrated: (state) => {
    set({ _hasHydrated: state });
  },
});

berita = persist(berita, {
  name: "data-berita",
  onRehydrateStorage: () => (state) => {
    state.setHasHydrated(true);
  },
});

export const BeritaStore = create(devtools(berita));
