import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import axios from "axios";
import { apiJadwal, keyJadwal } from "../libs/vars";

let jadwal = (set, get) => ({
  _hasHydrated: false,
  auth: "",
  dataState: [],
  fetchJadwalStore: async () => {
    const response = await axios.get(apiJadwal + "/v1", {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": keyJadwal,
      },
    });
    set({ dataState: await response.data });
  },
  updateJadwalAuth: (key) => set({ auth: key }),
  updateJadwalStore: (data) => set({ dataState: data }),
  viewSingleJadwal: (ids) => get().dataState.filter((item) => item.id === ids),
  clearJadwalStore: () => set({ dataState: [] }),
  setHasHydrated: (state) => {
    set({ _hasHydrated: state });
  },
});

jadwal = persist(jadwal, {
  name: "data-jadwal",
  onRehydrateStorage: () => (state) => {
    state.setHasHydrated(true);
  },
});

export const JadwalStore = create(devtools(jadwal));
